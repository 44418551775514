import styled from 'styled-components'
import {
  BreakpointHelpers,
} from '@sh24/ui-components'
import ResultsButton from './results-button'

const { tabletUp } = BreakpointHelpers

export const AvailableService = styled.div`
  ${({ theme }) => `
    padding ${theme?.spacing?.md} 0;
    border-bottom: 1px solid ${theme?.palette?.tertiary};
    &:last-child {
      border-bottom: none;
    }

    ${tabletUp(`
      display: flex;
    `)}

    align-items: center;

    > *:first-child {
      padding-right: ${theme?.spacing?.lg}
    }

    > * {
      flex: 1;
    }
  `}
`

const Service = ({
  intro,
  buttons = [],
  buttonProps = { arrow: true },
  children = null,
} : {
  intro: React.ReactNode,
  buttons?: { url: string, text: string }[] | undefined,
  buttonProps?: { arrow?: boolean, variation?: string },
  children?: React.ReactNode | null
}) => (
  <AvailableService>
    <div>
      {intro}
    </div>
    <div>
      {buttons?.map(({ url, text }) => <ResultsButton url={url} text={text} {...buttonProps} />)}
      {children}
    </div>
  </AvailableService>
)

export default Service
