import React from 'react'
import ContraceptionComparisonToolContainer from './contraception-comparison-tool-container'
import { ContraceptionOption } from './types'

interface ContraceptionComparisonToolModuleProps {
  toolHeading: string,
  resetButtonText: string,
  contraceptionComparisonOptions: Array<ContraceptionOption>,
  compareOtherOptionsButtonText: string,
}

const ContraceptionComparisonToolModule = ({
  toolHeading,
  resetButtonText,
  contraceptionComparisonOptions,
  compareOtherOptionsButtonText,
}: ContraceptionComparisonToolModuleProps) => (
  <ContraceptionComparisonToolContainer
    options={contraceptionComparisonOptions}
    toolHeading={toolHeading}
    resetButtonText={resetButtonText}
    compareOtherOptionsButtonText={compareOtherOptionsButtonText}
  />
)

export default ContraceptionComparisonToolModule
