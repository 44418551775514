import { HelpSnippet, ButtonLink } from '@sh24/ui-components'
import InlineLink from '../../InlineLink/inline-link'
import documentToReactComponents from '../../../utils/document-to-react-components'

const HelpSnippetModule = ({
  title,
  content,
  link,
  accordion,
}) => {
  const document = documentToReactComponents(content)
  const CallToAction = (link?.text && link?.url) ? (
    <ButtonLink variation="text" animation="shiftRight" iconPosition="right">
      <InlineLink
        {...link}
        iconName="arrow-right"
        context="help-snippet"
      />
    </ButtonLink>
  ) : null

  return (
    <HelpSnippet
      title={title}
      content={document}
      cta={CallToAction}
      accordion={accordion}
    />
  )
}

export default HelpSnippetModule
