import React from 'react'
import { ContraceptionComparisonDetailHeaderCard } from '@sh24/ui-components'
import { ContraceptionOption } from '../types'
import Image from '../../../Image/image'

interface HeaderProps {
  selectedOptions: ContraceptionOption[],
  removeFromSelectionHandler: (contraceptionId: string) => void
}

const renderTitles = (
  { id, name }: ContraceptionOption,
  removeFromSelectionHandler: (contraceptionId: string) => void) => (
    <div key={id} className="name-wrapper">
      <h3>{name}</h3>
      <div
        data-testid={id}
        role="button"
        className="close-btn"
        onClick={() => removeFromSelectionHandler(id)}
        onKeyDown={() => removeFromSelectionHandler(id)}
        tabIndex={0}
      >
        x
      </div>
    </div>
)

const renderImages = ({ id, image }: ContraceptionOption) => (
  <div key={id} className="image-wrapper">
    <Image
      image={image}
      maxHeight={100}
      maxWidth={100}
      layout="intrinsic"
    />
  </div>
)

const header = ({
  selectedOptions,
  removeFromSelectionHandler,
}: HeaderProps) => (
  <ContraceptionComparisonDetailHeaderCard
    titles={
      selectedOptions.map((option) => renderTitles(option, removeFromSelectionHandler))
    }
    images={selectedOptions.map(renderImages)}
  />
)

export default header
