import { Slot, DateGroupedSlots } from './types'

export const formatDate = (date: Date): string => (
  date.toLocaleString('en-GB', {
    weekday: 'long',
    month: 'long',
    day: 'numeric',
  }).replace(',', '')
)

export const formatTime = (date: Date): string => (
  date.toLocaleString('en-US', {
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
  })
)

export const formatTimeWithoutAMPM = (date: Date): string => (
  formatTime(date).replace(/AM|PM/, '').trimEnd()
)

const isBeforeNoon = (time: string): boolean => (
  (new Date(time)).getHours() < 12
)

const removeDuplicateTimeSlots = (slots: Array<Slot>) => (
  slots.filter((slot, index, self) => (
    self.findIndex((s) => s.start_at === slot.start_at) === index
  ))
)

export const slotsGroupedByDate = (availableSlots: Array<Slot>): Array<DateGroupedSlots> => {
  const obj = availableSlots.reduce((grouped: { [key: string]: { [key: string]: Array<Slot> } }, slot: Slot) => {
    const date = formatDate(new Date(slot.start_at))
    if (!grouped[date]) {
      grouped[date] = { morning: [], afternoon: [] } // eslint-disable-line no-param-reassign
    }
    if (isBeforeNoon(slot.start_at)) {
      grouped[date].morning.push(slot)
    } else {
      grouped[date].afternoon.push(slot)
    }
    return grouped
  }, {})

  return Object.keys(obj).reduce((acc: Array<DateGroupedSlots>, date) => [
    ...acc,
    {
      date,
      morningSlots: removeDuplicateTimeSlots(obj[date].morning),
      afternoonSlots: removeDuplicateTimeSlots(obj[date].afternoon),
    },
  ], [])
}
