import bookSlot from './consultation-booking/book-slot'
import cancelAppointment from './consultation-booking/cancel-appointment'
import getAvailableSlots from './consultation-booking/get-available-slots'
import getInvitation from './consultation-booking/get-invitation'

export default {
  bookSlot,
  cancelAppointment,
  getAvailableSlots,
  getInvitation,
}
