import { getServiceName } from '../config'

const getServiceConfig = async (ctx) => {
  const service = getServiceName({ slug: ctx.journey.slug })
  const postcode = ctx.postcode || ctx.deliveryAddress?.postcode

  const data = JSON.stringify({
    postcode,
    service,
    referrerToken: ctx.referrer_token || ctx.token,
  })

  const result = await fetch('/api/service-configuration', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: data,
  })

  const parsed = await result.json()

  if (result.status !== 200) return Promise.reject(parsed)

  return {
    context: {
      ...parsed?.data?.service_configuration,
    },
  }
}

export default getServiceConfig
