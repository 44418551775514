import { useContext, useEffect } from 'react'
import AccountContext from '../contexts/account-context'

const ensurePrescriptionsLoaded = () => {
  const {
    allPrescriptions,
    refreshPrescriptions,
  } = useContext(AccountContext).prescriptionsContext

  useEffect(() => {
    const updatePrescriptions = async () => {
      if (refreshPrescriptions && !allPrescriptions) {
        await refreshPrescriptions()
      }
    }

    updatePrescriptions()
  }, [refreshPrescriptions, allPrescriptions])
}

export default ensurePrescriptionsLoaded
