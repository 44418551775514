const TYPE_OF_KIT_MAPPING = {
  'Chlamydia::Vaginal': 'swab',
  'Chlamydia::Urine': 'urine',
}
const assignTypeOfKitFromKitContents = ({ tests }) => {
  const typeOfKit = tests.map((test) => TYPE_OF_KIT_MAPPING[test]).filter((test) => test)[0]

  return {
    context: {
      typeOfKit,
    },
  }
}

export default assignTypeOfKitFromKitContents
