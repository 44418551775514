export default function countryForBrand(brand) {
  switch (brand) {
    case 'hep_c_ireland':
      return 'IE'
    case 'sh24_ireland':
      return 'IE'
    case 'fettle':
      return 'GB'
    case 'sh24':
      return 'GB'
    default:
      return 'GB'
  }
}
