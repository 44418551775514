import countryForBrand from '../../../utils/country-for-brand'

const getPhoneCountryCode = (number, regions) => (
  number?.countryCode
  || regions?.[0]
  || 'GB'
)

const postcodeValidations = {
  GB: [{ rule: 'required', messageKey: 'postcodeRequired' }, 'postcode'],
  IE: [{ rule: 'required', messageKey: 'eircodeRequired' }, 'eircode'],
}

const addressValidations = (key, ctx) => ({
  [`${key}.addressLine1`]: [
    { rule: 'required', messageKey: 'addressLine1Required' },
    { rule: 'minLength:5', messageKey: 'addressLine1Length' },
    { rule: 'doesNotContainEmoji', messageKey: 'addressLine1Invalid' },
    { rule: 'doesNotContainPhoneNumber', messageKey: 'addressLine1Invalid' },
  ],
  [`${key}.addressLine2`]: [
    { rule: 'doesNotContainEmoji', messageKey: 'addressLine2Invalid' },
    { rule: 'doesNotContainPhoneNumber', messageKey: 'addressLine2Invalid' },
  ],
  [`${key}.city`]: [
    { rule: 'required', messageKey: 'cityRequired' },
    { rule: 'doesNotContainEmoji', messageKey: 'cityInvalid' },
    { rule: 'doesNotContainPhoneNumber', messageKey: 'cityInvalid' },
  ],
  [`${key}.postcode`]: postcodeValidations[countryForBrand(ctx.brand)],
})

const customValidations = {
  addressLookup: (key, ctx) => addressValidations(key, ctx),
  addressLookupPrefilled: (key, ctx) => addressValidations(key, ctx),
  bloodPressure: (key) => ({
    [`${key}.systolic`]: ['required', 'greaterThanEqualTo:20', 'lessThanEqualTo:400'],
    [`${key}.diastolic`]: ['required', 'greaterThanEqualTo:20', 'lessThanEqualTo:400'],
  }),
  dob: (key) => ({
    [`${key}.dd`]: [
      { rule: 'required', messageKey: 'invalidDay' },
      { rule: 'betweenInclusive:1:31', messageKey: 'invalidDay' },
    ],
    [`${key}.mm`]: [
      { rule: 'required', messageKey: 'invalidMonth' },
      { rule: 'betweenInclusive:1:12', messageKey: 'invalidMonth' },
    ],
    [`${key}.yyyy`]: [
      { rule: 'required', messageKey: 'invalidYear' },
      { rule: 'greaterThanEqualTo:1900', messageKey: 'invalidYear' },
    ],
    [key]: ['isValidDateOfBirth'],
  }),
  height: (key, ctx) => {
    const unitChecks = {
      metric: {
        [`${key}.centimeters`]: ['required', 'integer', 'greaterThanEqualTo:60', 'lessThanEqualTo:280'],
      },
      imperial: {
        [`${key}.feet`]: ['required', 'integer', 'greaterThanEqualTo:2', 'lessThanEqualTo:8'],
        [`${key}.inches`]: ['required', 'integer', 'greaterThanEqualTo:0', 'lessThan:12'],
      },
    }

    return {
      [`${key}.unit`]: ['required'],
      ...(unitChecks[ctx[key]?.unit] || {}),
    }
  },
  partnerPhoneNumbers: (key, ctx) => {
    const entry = ctx[key]
    const entryRules = {}
    if (typeof entry === 'object') {
      Object.keys(entry).forEach((entryId) => {
        const subKey = `${key}.${entryId}`
        const countryCode = getPhoneCountryCode(entry[entryId], ctx.phone_regions)
        entryRules[subKey] = [
          {
            rule: `isMobilePhoneNumber:${countryCode}`,
            messageKey: `partnerPhoneNumber${countryCode}`,
          },
        ]
      })
    }

    return {
      [key]: ['required', 'entriesGreaterThan:0'],
      ...entryRules,
    }
  },
  phonenumber: (key, ctx) => {
    const countryCode = getPhoneCountryCode(ctx.phoneNumber, ctx.phone_regions)
    return {
      [key]: [
        'required',
        {
          rule: `isMobilePhoneNumber:${countryCode}`,
          messageKey: `phoneNumber${countryCode}`,
        },
      ],
    }
  },
  productSelectionPrimary: (_, ctx) => {
    const hasSecondary = ctx.products.some((prod) => prod.isSecondary)
    const hasMutuallyExclusive = ctx.products.some((prod) => prod.mutuallyExclusive)

    return (hasSecondary && !hasMutuallyExclusive) ? {} : {
      basket: [
        'required',
        {
          rule: 'basketHasPrimaryItems',
          messageKey: 'basketNotEmpty',
        },
      ],
    }
  },
  productSelectionSecondary: () => ({
    basket: [
      'required',
      {
        rule: 'basketHasMainItems',
        messageKey: 'basketNotEmpty',
      },
    ],
  }),
  productSelectionExtras: () => ({
    basket: [
      'required',
      {
        rule: 'basketHasMainItems',
        messageKey: 'basketNotEmpty',
      },
    ],
  }),
  weight: (key, ctx) => {
    const unitChecks = {
      metric: {
        [`${key}.kg`]: ['required', 'integer', 'greaterThanEqualTo:10', 'lessThanEqualTo:450'],
      },
      imperial: {
        [`${key}.stone`]: ['required', 'integer', 'greaterThanEqualTo:2', 'lessThanEqualTo:70'],
        [`${key}.pounds`]: ['required', 'integer', 'greaterThanEqualTo:0', 'lessThan:14'],
      },
    }

    return {
      [`${key}.unit`]: ['required'],
      ...(unitChecks[ctx[key]?.unit] || {}),
    }
  },
}

export default customValidations
