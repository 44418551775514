import { buildProduct } from '../../helpers'

const removeList = {
  sti_test_order: [],
  test_at_home_order: ['hepatitis_b', 'hiv'],
}

const stiStrategy = (ctx) => {
  const { products: journeyProducts } = ctx.journey
  const {
    orderType,
    hivDiagnosed,
    hivDiagnosedConfirmation,
    currency,
    locale,
  } = ctx
  const hivPositive = hivDiagnosed === 'yes' && hivDiagnosedConfirmation === 'yes'

  return journeyProducts
    .filter((product) => {
      if (product.isExtra) return false
      if (product.identifier === 'hiv' && hivPositive) return false
      if (removeList[orderType]?.includes(product.identifier)) return false
      return true
    }).map((product) => buildProduct({
      product,
      mandatory: false,
      preselected: false,
      currency,
      locale,
    }))
}

const extrasStrategy = (ctx) => {
  const { products: journeyProducts } = ctx.journey
  const { currency, locale } = ctx
  return journeyProducts
    .filter((product) => product.isExtra)
    .map((product) => buildProduct({
      product,
      mandatory: false,
      preselected: false,
      currency,
      locale,
    }))
}

const hepCProductAvailibilityHandler = async (ctx) => {
  const products = stiStrategy(ctx)
  const extras = extrasStrategy(ctx)

  return {
    products: [
      ...products,
      ...extras,
    ],
  }
}

export default hepCProductAvailibilityHandler
