import { formatPrice } from '../../../utils/helpers'
import calculateMultiProductDiscount from './calculate-multi-product-discount'
import calculatePromoDiscount from './calculate-promo-discount'

const createTotal = ({
  id,
  label,
  translationKey,
  amount,
  locale,
  currency,
  isGrandTotal = false,
}) => {
  const flatAmount = Math.ceil(amount)

  return {
    id,
    label,
    translationKey,
    amount: flatAmount,
    formattedAmount: formatPrice({
      price: flatAmount, locale, currency,
    }),
    isGrandTotal,
  }
}

/**
 * The sum of item prices in a customer's basket.
 */
const getBasketTotal = ({
  basket,
  currency,
  locale,
}) => {
  const amount = basket.reduce((total, item) => {
    const itemPrice = item.selectedOption ? item.selectedOption.price : item.price
    return total + itemPrice
  }, 0)

  return createTotal({
    id: 'price',
    translationKey: 'orderJourney.totals.price',
    amount,
    currency,
    locale,
  })
}

/**
 * The value of any multi product discounts based on a customer's basket.
 */
const getMultiProductDiscountTotal = ({
  basket,
  currency,
  locale,
  discountSettings,
}) => {
  const amount = calculateMultiProductDiscount({ basket, discountSettings })
  return createTotal({
    id: 'multiTestDiscount',
    translationKey: 'orderJourney.totals.multiTestDiscount',
    amount,
    currency,
    locale,
  })
}

const getPromoDiscountTotal = ({
  price,
  discount,
  currency,
  locale,
}) => {
  const amount = calculatePromoDiscount({ price, discount })
  return createTotal({
    id: 'promoDiscount',
    label: discount.code,
    translationKey: 'orderJourney.totals.promo',
    amount,
    locale,
    currency,
  })
}

const getDeliveryTotal = ({ price, currency, locale }) => createTotal({
  id: 'deliveryTotal',
  translationKey: 'orderJourney.totals.delivery',
  amount: price,
  currency,
  locale,
})

const getGrandTotal = ({ price, currency, locale }) => createTotal({
  id: 'grandTotal',
  translationKey: 'orderJourney.totals.grandTotal',
  amount: price,
  currency,
  locale,
  isGrandTotal: true,
})

const calculateBasketTotals = ({
  basket = [],
  discount = {},
  currency = 'GBP',
  locale = 'en-GB',
  discountSettings,
}) => {
  if (!basket || basket.length === 0) return {}

  const basketTotal = getBasketTotal({ basket, currency, locale })

  const multiProductDiscount = getMultiProductDiscountTotal({
    basket,
    currency,
    locale,
    discountSettings,
  })

  const promoDiscount = getPromoDiscountTotal({
    price: basketTotal.amount + multiProductDiscount.amount,
    discount,
    currency,
    locale,
  })

  const grandTotal = getGrandTotal({
    price: basketTotal.amount + multiProductDiscount.amount + promoDiscount.amount,
    currency,
    locale,
  })

  const deliveryTotal = getDeliveryTotal({
    price: 0,
    currency,
    locale,
  })

  return {
    basketTotal,
    deliveryTotal,
    multiProductDiscount,
    promoDiscount,
    grandTotal,
  }
}

export default calculateBasketTotals
