import {
  getCurrentStep,
  getQuestionKeyForService,
} from '../helpers'
import * as services from '.'
import createCheckitError from '../helpers/create-checkit-error'

const serviceProxy = async (ctx) => {
  const step = getCurrentStep(ctx)
  const lastQuestionKey = getQuestionKeyForService(ctx)
  const serviceFn = services[step.service]

  if (!serviceFn) {
    const error = createCheckitError(lastQuestionKey, 'Service unavailable')
    return Promise.reject(error)
  }

  try {
    const res = await serviceFn(ctx)
    return res
  } catch (e) {
    const error = createCheckitError(lastQuestionKey, e.message)
    return Promise.reject(error)
  }
}

export default serviceProxy
