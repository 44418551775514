import Link from 'next/link'
import styled from 'styled-components'
import { Content, PillButton } from '@sh24/ui-components'
import Image from '../../Image/image'
import { usePageContext } from '../../../contexts/page-context'

const List = styled.ul`
  ${({ theme }) => `
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding-left: ${theme?.spacing?.zero};
    margin-bottom: ${theme?.spacing?.zero};
  `}
`

const ListItem = styled.li`
  ${({ theme }) => `
    list-style-type: none;
    margin-right: ${theme?.spacing?.base};
    margin-bottom: ${theme?.spacing?.base};

    &:last-child {
      margin-bottom: ${theme?.spacing?.base};
    }
  `}
`

const ButtonCloudModule = ({ title, items }) => {
  if (!items) return null
  const page = usePageContext()
  const renderHeader = page?.features?.moduleHeaders
  const headerContent = title ? <h2 key={title}>{title}</h2> : null

  return (
    <>
      { renderHeader && headerContent && (
        <Content
          width="standard"
          textAlign="center"
          content={headerContent}
        />
      )}
      <div className="row row-centered">
        <div className="col col-12 col-md-8">
          <List>
            {items.map((item) => {
              const imageComponent = item.image ? (
                <Image
                  image={item.image}
                  maxHeight={48}
                  maxWidth={48}
                  layout="intrinsic"
                />
              ) : null

              return (
                <ListItem key={item.name}>
                  <PillButton>
                    <Link href={item.url} prefetch={false}>
                      <a>
                        <PillButton.Inner image={imageComponent} text={item.title} />
                      </a>
                    </Link>
                  </PillButton>
                </ListItem>
              )
            })}
          </List>
        </div>
      </div>
    </>
  )
}

export default ButtonCloudModule
