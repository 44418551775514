import { AvailableSlotsResponseData } from '@sh24/admin-api-js'
import { errorMessage } from '../../utils/api-utils'
import { ErrorData } from '../../types/api'

const getAvailableSlots = async (token: string | null): Promise<AvailableSlotsResponseData|ErrorData> => {
  try {
    const response = await fetch(`/api/v2/consultation-booking/available-slots?token=${token}`)
    const parsedResponse = await response.json()

    return parsedResponse
  } catch (error) {
    return { errors: [{ message: errorMessage(error) }] }
  }
}

export default getAvailableSlots
