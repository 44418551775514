const defaultPermitted = [
  'selected',
  'productId',
  'token',
  'referrer',
  'referrer_token',
  'utm_campaign',
  'utm_medium',
  'utm_source',
  'utm_term',
  'utm_content',
  'code',
  'pn_token',
  'sh24Uid',
  'userKitCode',
]

const getValidQueryParams = (query, permitted = defaultPermitted) => {
  const arr = Object.entries(query)
  const valid = arr.filter(([key]) => permitted.includes(key))
  return Object.fromEntries(valid)
}

export default getValidQueryParams
