import { useState } from 'react'
import { SearchButton } from '@sh24/ui-components'
import SearchModal from '../../SearchModal/search-modal'

const BigSearch = ({
  placeholder,
  openSearch,
  offSet,
  ...searchProps
}) => {
  const [searchActive, setSearchActive] = useState(false)

  return (
    <>
      <SearchButton
        text={placeholder}
        ariaLabel={openSearch}
        onClick={() => setSearchActive(!searchActive)}
        offSet={offSet}
      />
      <SearchModal
        config={{
          placeholder, openSearch, offSet, ...searchProps,
        }}
        active={searchActive}
        onClose={() => setSearchActive(!searchActive)}
      />
    </>
  )
}

export default BigSearch
