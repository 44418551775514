import {
  Content,
  Highlight,
  ServiceHighlight,
} from '@sh24/ui-components'
import Image from '../../Image/image'
import documentToReactComponents from '../../../utils/document-to-react-components'
import { useSectionContext } from '../../../contexts/section-context'

const buildHighlight = (blend, alternate, sectionBgColour) => ({
  id,
  type,
  image,
  content,
  ringColour,
  backgroundColour,
}, index) => {
  const document = documentToReactComponents(content)
  const isService = type === 'service'
  const Component = isService ? ServiceHighlight : Highlight

  return (
    <div key={id} className={`col col-12 ${isService ? 'col-md-6' : ''}`}>
      <Component
        index={index}
        content={document}
        image={(
          <Image
            image={image}
            maxHeight={110}
            maxWidth={110}
            layout="intrinsic"
          />
        )}
        blend={blend}
        alternate={alternate}
        ringColour={blend ? sectionBgColour : ringColour}
        bgColour={blend ? null : backgroundColour}
      />
    </div>
  )
}

const HighlightsModule = ({
  title,
  intro,
  blend,
  alternate,
  highlights,
}) => {
  const sectionCtx = useSectionContext()
  const sectionBgColour = sectionCtx.backgroundColour
  const headerContent = [
    (title ? <h2 key={title}>{title}</h2> : null),
    (intro ? documentToReactComponents(intro) : null),
  ].filter((item) => !!item)

  return (
    <>
      {(headerContent.length > 0) && (
        <div className="mb-lg">
          <Content width="standard" textAlign="center" content={headerContent} />
        </div>
      )}
      <div className="row row-centered">
        <div className="col col-12 col-md-6">
          <div className="row row-centered">
            {highlights.map(buildHighlight(blend, alternate, sectionBgColour))}
          </div>
        </div>
      </div>
    </>
  )
}

export default HighlightsModule
