import { format } from 'date-fns'
import { HistoricalOrder, HistoricalOrdersData } from '@sh24/admin-api-js'
import {
  ByYearAndDate, OrderCard, Tag, ButtonLink,
} from '@sh24/ui-components'
import styled from 'styled-components'
import useTranslations from '../../utils/use-translations'
import { camelize, titleize } from '../../utils/helpers'
import InlineLink from '../InlineLink/inline-link'
import prepareTestData from './prepare-test-data'

const OrderSummaryTag = styled(Tag)`
  ${({ theme }) => `
    margin-top: ${theme?.spacing?.sm};
    margin-right: ${theme?.spacing?.sm};
    margin-bottom: ${theme?.spacing?.sm};
  `}
`
const iconMap: Record<string, string> = {
  stiTestOrder: 'swab-urine',
}

const ViewResultsButton = ({ text, url }: { text: string, url: string }) => (
  <ButtonLink variation="primary" fullWidth iconPosition="none">
    <InlineLink
      text={text}
      url={url}
    />
  </ButtonLink>
)

const OrdersHistoryDisplay = ({ orders }: { orders: HistoricalOrdersData }) => {
  const translations = useTranslations()

  const renderOrderCard = (order: HistoricalOrder) => {
    const viewResultsButton = order.resultsState ? (
      <ViewResultsButton
        text={translations[`orderCard.viewResultsButtonText.${order.resultsState}`]}
        url={`/account/results/${order.sh24Uid}`}
      />
    ) : null

    return (
      <OrderCard
        tagText={translations[`orderCard.${camelize(order.orderType)}`] || titleize(order.orderType)}
        tagIcon={iconMap[order.orderType] || 'tablet-capsule'}
        items={prepareTestData(order, translations)}
        extraInfo={order.prepSubscription ? translations['orderCard.extraInfo'] : undefined}
        orderId={`${translations['orderCard.orderId']} ${order.sh24Uid}`}
        status={`${translations[`orderCard.${order.orderState}`] || order.orderState} ${format(order.updatedAt, 'dd LLLL yyyy')}`}
        bottomSectionLower={viewResultsButton}
      />
    )
  }

  const ordersDisplay = orders.orders
    .sort(({ createdAt: a }, { createdAt: b }) => b.valueOf() - a.valueOf())
    .map((order, index) => (
      {
        date: order.createdAt,
        content: renderOrderCard(order),
        previewContent: (
          <OrderSummaryTag
            key={order.sh24Uid}
            text={translations[`orderCard.${camelize(order.orderType)}`] || ''}
            icon={iconMap[order.orderType] || 'tablet-capsule'}
          />
        ),
        defaultOpen: index === 0,
      }
    ))

  return (
    <ByYearAndDate items={ordersDisplay} />
  )
}

export default OrdersHistoryDisplay
