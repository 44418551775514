import { format, subDays } from 'date-fns'
import { ButtonLink, Callout } from '@sh24/ui-components'
import styled from 'styled-components'
import useTranslations from '../../../../utils/use-translations'
import InlineLink from '../../../InlineLink/inline-link'

interface PrepOrderData {
  orderState: string
  updatedAt: Date
  trackingLink: string | null
}

interface State {
  title: string
  content: string
  button: {
    text: string
    link?: string
  } | null
  showStatusUpdate: boolean
}

const CtaButtonOuterWrapper = styled.div`
  div {
    a {
      justify-content: center;
    }
  }
`

const fetchStatusDetails = (order: PrepOrderData, translations: Record<string, string>): { [state: string]: State} => (
  {
    created: {
      title: translations['prepDashboard.prepOrderStatusCard.title.created'],
      content: translations['prepDashboard.prepOrderStatusCard.description.created'],
      button: null,
      showStatusUpdate: true,
    },
    approved: {
      title: translations['prepDashboard.prepOrderStatusCard.title.approved'],
      content: translations['prepDashboard.prepOrderStatusCard.description.approved'],
      button: null,
      showStatusUpdate: true,
    },
    prescribed: {
      title: translations['prepDashboard.prepOrderStatusCard.title.prescribed'],
      content: translations['prepDashboard.prepOrderStatusCard.description.prescribed'],
      button: null,
      showStatusUpdate: true,
    },
    dispatched: {
      title: translations['prepDashboard.prepOrderStatusCard.title.dispatched'],
      content: translations['prepDashboard.prepOrderStatusCard.description.dispatched'],
      button: {
        text: translations['prepDashboard.prepOrderStatusCard.ctaButtonText.dispatched'],
        link: order.trackingLink as string,
      },
      showStatusUpdate: order.updatedAt > subDays(new Date(), 7),
    },
  }
)

const PrepOrderStatus = ({ prepOrderData = null } : {prepOrderData?: PrepOrderData | null}) => {
  const translations = useTranslations()
  const renderStatusCard = (orderData: PrepOrderData) => {
    const statusDetails = fetchStatusDetails(orderData, translations)[orderData.orderState]

    if (statusDetails === undefined || (statusDetails && !statusDetails.showStatusUpdate)) {
      return null
    }

    const ctaButton = statusDetails.button && statusDetails.button.link && (
      <CtaButtonOuterWrapper>
        <ButtonLink variation="primary" fullWidth>
          <InlineLink url={statusDetails.button.link} text={statusDetails.button.text} />
        </ButtonLink>
      </CtaButtonOuterWrapper>
    )

    return (
      <Callout
        key="prepOrder"
        preTitle={format(orderData.updatedAt, 'dd MMMM HH:mm')}
        backgroundColour="/special200"
        iconBackgroundColour="/special300"
        icon="bell"
        title={statusDetails.title}
        content={<p>{statusDetails.content}</p>}
        ctaButton={ctaButton}
      />
    )
  }

  return (
    <>
      {prepOrderData && renderStatusCard(prepOrderData)}
    </>
  )
}

export default PrepOrderStatus
