import verifyLuhnModN from '../helpers/luhn-mod-n-verifier'
import airbrake from '../../../services/exception-notifier/client'

const OLD_TOKEN_FORMAT = /^.{3}-.{3}$/
const NEW_TOKEN_FORMAT = /^.{6}$/

const validTokenFormat = (token) => (
  OLD_TOKEN_FORMAT.test(token) || NEW_TOKEN_FORMAT.test(token)
)

const OLD_TOKEN_CHARACTER_SET = 'abcdefghjkmnpqrstuvwxyz'
const NEW_TOKEN_CHARACTER_SET = 'abcdefghjkmnpqrstuwxyz'

const validCheckDigit = (token) => {
  const tokenWithoutHypens = token.replace(/[-]/g, '')

  if (OLD_TOKEN_FORMAT.test(token)) {
    return verifyLuhnModN(OLD_TOKEN_CHARACTER_SET, tokenWithoutHypens)
  }
  return verifyLuhnModN(NEW_TOKEN_CHARACTER_SET, tokenWithoutHypens)
}

export const tokenParser = async ({ partnerNotificationContactToken }) => {
  const tokenWithoutPrefix = partnerNotificationContactToken.replace(/^pn-/, '')

  if (!validTokenFormat(tokenWithoutPrefix)
    || !validCheckDigit(tokenWithoutPrefix)) {
    const error = {
      message: "Clinic visit reference is invalid - please ensure you've typed it carefully",
    }
    return Promise.reject(error)
  }

  return {
    offerToken: partnerNotificationContactToken,
  }
}

const parsePartnerNotificationToken = async (ctx) => {
  try {
    const data = await tokenParser(ctx)
    return data
  } catch (err) {
    await airbrake.notify(err)
    throw err
  }
}

export default parsePartnerNotificationToken
