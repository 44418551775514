import { useRef, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import { BodyScrollLock, BreakpointHelpers } from '@sh24/ui-components'
import { CSSTransition } from 'react-transition-group'
import FocusTrap from 'focus-trap-react'
import Search from '../Search/search'

const { tabletUp } = BreakpointHelpers

const Overlay = styled.div`
  ${({ theme, active }) => `
    ${active ? 'display: block;' : 'display: none;'}
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(49, 55, 56, 0.5);
    width: 100%;
    height: 100%;
    margin: 0 auto;
    z-index: ${theme?.zIndex?.max};
  `}
`

const Modal = styled.section`
  ${({ theme }) => `
    position: relative;
    width: 100%;
    top: ${theme?.spacing?.lg};

    &.enter {
      transform: translateY(20%);
    }

    &.enter-active {
      transform: translateY(0);
      transition: transform 300ms cubic-bezier(0, .4, .62, 1.3);
    }

    ${tabletUp(`
      margin: 0 auto;
      width: 642px;
    `)}
  `}
`

const SearchModal = ({
  config,
  onClose,
  active = false,
  locale = 'en-GB',
}) => {
  const overlayRef = useRef(null)

  const closeModal = (event) => {
    if (event.target === overlayRef.current) {
      onClose()
    }
  }

  const handleEsc = useCallback((event) => {
    if (event.key === 'Escape' && active) {
      onClose()
    }
  }, [active, onClose])

  useEffect(() => {
    window.addEventListener('keydown', handleEsc)
    return () => window.removeEventListener('keydown', handleEsc)
  }, [handleEsc])

  return (
    <>
      <BodyScrollLock active={active} />
      <Overlay active={active} ref={overlayRef} onClick={closeModal}>
        <CSSTransition in={active} timeout={300}>
          <FocusTrap
            active={active}
            focusTrapOptions={{
              escapeDeactivates: false,
              clickOutsideDeactivates: true,
            }}
          >
            <Modal>
              <Search
                rounded
                config={config}
                onClose={onClose}
                locale={locale}
                closeButtonAriaLabel={config.closeSearch}
              />
            </Modal>
          </FocusTrap>
        </CSSTransition>
      </Overlay>
    </>
  )
}

export default SearchModal
