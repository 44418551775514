import { useState, useEffect } from 'react'
import { OrderSummary } from '@sh24/ui-components'
import Image from '../../Image/image'
import documentToReactComponents from '../../../utils/document-to-react-components'
import {
  CURRENT_JOURNEY_KEY,
  getSession,
} from '../../../order-journeys/_v2/state/actions'

const OrderSummaryModule = ({
  image,
  intro,
  addressHeading,
  deliveryHeading,
  deliveryWindow,
  detailsHeading,
  moreInformation,
}) => {
  const [address, setAddress] = useState(null)
  const [details, setDetails] = useState(null)
  const introComponents = documentToReactComponents(intro)
  const moreInfoComponents = documentToReactComponents(moreInformation)
  const summaryImage = (
    <Image
      image={image}
      maxHeight={180}
      maxWidth={180}
      layout="fill"
    />
  )

  useEffect(() => {
    const sessionKey = window.sessionStorage.getItem(CURRENT_JOURNEY_KEY)
    const session = getSession({ sessionKey })
    const { basket, deliveryAddress } = session?.context || {}

    setAddress([
      deliveryAddress?.addressLine1,
      deliveryAddress?.addressLine2,
      deliveryAddress?.city,
      deliveryAddress?.postcode,
    ].filter((row) => !!row))

    setDetails(
      basket?.map((item) => ({
        name: item.productName,
        byline: item.byline,
      })),
    )
  }, [])

  return (
    <OrderSummary
      image={summaryImage}
      intro={introComponents}
      addressHeading={addressHeading}
      address={address}
      deliveryHeading={deliveryHeading}
      deliveryWindow={deliveryWindow}
      detailsHeading={detailsHeading}
      details={details}
      moreInformation={moreInfoComponents}
    />
  )
}

export default OrderSummaryModule
