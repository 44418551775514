import airbrake from '../../../services/exception-notifier/client'

/* eslint-disable camelcase */
const decrementQuota = async ({ region_lookup, orderType }) => {
  const data = JSON.stringify({
    regionLookup: region_lookup,
    orderType,
  })

  const res = await fetch('/api/decrement-quota', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: data,
  })

  if (res.ok) return {}

  await airbrake.notify('decrementQuota failed')
  throw new Error('We\'re sorry. Something has gone wrong.')
}

export default decrementQuota
