import { BreakpointHelpers, Callout } from '@sh24/ui-components'
import styled from 'styled-components'
import useTranslations from '../../../../utils/use-translations'

interface InformationType {
  servicesAvailableToOrder: boolean
}

const { mobileOnly, tabletUp } = BreakpointHelpers

const Container = styled.div<{ servicesAvailableToOrder: boolean }>`
  ${({ servicesAvailableToOrder }) => `
    flex: 5;
    width: 100%;
    height: 100%;

    > *:first-child {
      height: 100%;
    }

    ${tabletUp(`
          align-self: flex-start;
          max-width: 70%;

          ${servicesAvailableToOrder ? 'max-width: 70%;' : 'max-width: 100%;'}
        `)}

    ${mobileOnly(`
        align-self: center;
      `)}
  `}
`

const Information = ({ servicesAvailableToOrder }: InformationType) => {
  const translations = useTranslations()
  const title = translations['prepDashboard.orderAvailabilityInformation.title']
  const description = translations['prepDashboard.orderAvailabilityInformation.description']

  const descriptionParagraphs = description.split('. ').map((desc) => (
    <p key={desc}>{desc}</p>
  ))

  return (
    <Container servicesAvailableToOrder={servicesAvailableToOrder}>
      <Callout
        title={title}
        content={descriptionParagraphs}
        backgroundColour="/tertiary300"
        icon="tablet-capsule"
        iconBackgroundColour="/white"
      />
    </Container>
  )
}

export default Information
