import { ButtonLink, SupportLink } from '@sh24/ui-components'
import InlineLink from '../../InlineLink/inline-link'
import documentToReactComponents from '../../../utils/document-to-react-components'

const SupportLinkModule = ({
  id,
  contentType,
  icon,
  content,
  link,
}) => {
  const document = documentToReactComponents(content)
  const iconPosition = 'left'
  const CallToAction = (link?.text && link?.url) ? (
    <ButtonLink variation="text" animation="spin" iconPosition={iconPosition}>
      <InlineLink
        {...link}
        iconName={icon}
        roundIcon
        iconPosition={iconPosition}
        context="support"
      />
    </ButtonLink>
  ) : null

  return (
    <SupportLink
      id={id}
      className={contentType}
      link={CallToAction}
      content={document}
    />
  )
}

export default SupportLinkModule
