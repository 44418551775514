const getAvailablePrepOrders = async (ctx) => {
  const { username } = ctx

  const data = JSON.stringify({
    username,
  })

  const result = await fetch('/api/prep-available-orders', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: data,
  })

  const parsed = await result.json()

  if (result.status !== 200) return Promise.reject(parsed)

  const prepSubscription = parsed.prepSubscription !== null
  const stiAvailable = parsed.availableServices ? parsed.availableServices.stiTestOrder : false
  const prepAvailable = parsed.availableServices ? parsed.availableServices.prepOrder : false

  return {
    context: {
      prepSubscription,
      stiAvailable,
      prepAvailable,
    },
  }
}

export default getAvailablePrepOrders
