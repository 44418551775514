import validatePostcode from './validate-postcode'

export interface Errors {
  [key: string]: Array<string>;
  age: Array<string>;
  postcode: Array<string>;
}

const validateAvailabilityCheckerFields = ({ age = '', postcode = '', translations }: { age: string, postcode: string, translations: Record<string, string> }): Errors => {
  const validationErrors: Errors = { age: [], postcode: [] }

  if (parseInt(age, 10) < 1) {
    validationErrors.age.push(translations['errors.ageMustBePositive'])
  } else if (!age.match(/^\d+$/)) {
    validationErrors.age.push(translations['errors.age'])
  }

  if (!validatePostcode(postcode)) {
    validationErrors.postcode.push(translations['errors.postcode'])
  }

  return validationErrors
}

export const hasAvailabilityCheckerFieldErrors = (validationErrors: Errors) => (
  Object.values(validationErrors).flat().length > 0
)

export default validateAvailabilityCheckerFields
