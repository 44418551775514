import airbrake from '../../../services/exception-notifier/client'

const checkPaymentIntent = async ({ totals, paymentIntent }) => {
  if (totals?.grandTotal?.amount === 0 || paymentIntent?.status === 'requires_capture') return { context: {} }

  const message = 'We were unable to process your payment. Please try again or use a different payment method.'

  const errorPayload = {
    ...paymentIntent,
    error: {
      message,
    },
  }
  await airbrake.notify(errorPayload)

  const error = new Error(message)

  return Promise.reject(error)
}

export default checkPaymentIntent
