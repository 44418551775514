import {
  Content,
  ButtonLink,
  SupportLink,
} from '@sh24/ui-components'
import InlineLink from '../../InlineLink/inline-link'
import documentToReactComponents from '../../../utils/document-to-react-components'

const buildSupportLink = ({
  id,
  contentType,
  icon,
  content,
  link,
}) => {
  const document = documentToReactComponents(content)
  const iconPosition = 'left'
  const CallToAction = (link?.text && link?.url) ? (
    <ButtonLink variation="text" animation="spin" iconPosition={iconPosition}>
      <InlineLink
        {...link}
        iconName={icon}
        roundIcon
        iconPosition={iconPosition}
        context="support"
      />
    </ButtonLink>
  ) : null

  return (
    <SupportLink
      id={id}
      key={id}
      className={contentType}
      link={CallToAction}
      content={document}
    />
  )
}

const SupportLinksModule = ({
  title,
  intro,
  links,
}) => {
  const headerContent = [
    (title ? <h2 key={title}>{title}</h2> : null),
    (intro ? documentToReactComponents(intro) : null),
  ].filter((item) => !!item)

  return (
    <>
      {(headerContent.length > 0) && <Content width="standard" textAlign="left" content={headerContent} />}
      {links.map(buildSupportLink)}
    </>
  )
}

export default SupportLinksModule
