const brandHandlers = {
  hep_c_ireland: (ctx) => (ctx.basket?.find((item) => item.identifier === 'hepatitis_c_self_test')
    ? 'test_at_home_order'
    : 'sti_test_order'),
}

const setOrderType = (ctx) => {
  const handler = brandHandlers[ctx.brand]
  const newOrderType = handler?.(ctx)

  return {
    context: {
      orderType: newOrderType || ctx.orderType,
    },
  }
}

export default setOrderType
