import { Content } from '@sh24/ui-components'
import styled from 'styled-components'
import { ReactNode } from 'react'
import useTranslations from '../../../utils/use-translations'
import BookingView from './booking-view'
import BookedView from './booked-view'
import { Slot, State } from './types'

const Alert = styled.div`
  background-color: ${({ theme }) => theme.palette.secondary500};
  border-radius: ${({ theme }) => theme.spacing.sm};
  text-align: center:
`

const AlignmentContainer = styled.div`
  max-width: 500px;
  margin: 0 auto;
`

const ConsultationBooking = ({
  bookedAt,
  bookFunction,
  bottomCopy,
  cancelFunction,
  loading,
  selectedSlot,
  slotsByDate,
  slotTaken,
  topCopy,
} : {
  bookedAt: State['bookedAt'],
  bookFunction: (slot: Slot) => Promise<void>,
  bottomCopy: ReactNode | null,
  cancelFunction: () => Promise<void>,
  loading: State['loading'],
  selectedSlot: State['selectedSlot'],
  slotsByDate: State['slotsByDate'],
  slotTaken: State['slotTaken'],
  topCopy: ReactNode | null,
}) => {
  const translations = useTranslations()
  const slotTakenMessage = translations['consultationAppointmentBookingModule.slotTaken']

  return (
    <>
      <div className="mb-lg">
        <Content width="standard" textAlign="center" content={topCopy} />
      </div>
      <div className="row row-centered">
        <div className="col col-12 col-md-6">
          <div className="row row-centered">
            <AlignmentContainer>
              {slotTaken && <Alert className="p-base m-xs">{slotTakenMessage}</Alert>}
              {
                  bookedAt ? (
                    <BookedView
                      bookedAt={bookedAt}
                      cancellationText={bottomCopy}
                      loading={loading}
                      onCancel={cancelFunction}
                    />
                  ) : (
                    <BookingView
                      loading={loading}
                      onBook={bookFunction}
                      selectedSlot={selectedSlot}
                      slotsByDate={slotsByDate}
                    />
                  )
              }
            </AlignmentContainer>
          </div>
        </div>
      </div>
    </>
  )
}

export default ConsultationBooking
