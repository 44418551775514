const calculateMultiProductDiscount = ({ basket, discountSettings }) => {
  const validItems = basket.filter((item) => !item.isExtra)
  const numberOfItems = validItems.length

  const bestDiscount = discountSettings.reduce((prev, current) => {
    if (current?.numberOfItems <= numberOfItems && (current?.numberOfItems > prev?.numberOfItems)) {
      return current
    }
    return prev
  }, { numberOfItems: 0, discountPercent: 0 })

  const { discountPercent } = bestDiscount
  const total = validItems.reduce((sum, item) => (
    sum + (item.selectedOption ? item.selectedOption.price : item.price)
  ), 0)

  return discountPercent > 0 ? -((total * discountPercent) / 100) : 0
}

export default calculateMultiProductDiscount
