const sendToKickout = (ctx, navigateTo) => {
  const kickout = ctx.journey.kickouts.find((k) => k.id === ctx.kickoutReason)

  const query = ctx.region_lookup
    ? new URLSearchParams({ region: ctx.region_lookup }).toString()
    : null

  const url = `/orders/unavailable/${kickout.slug}${query ? `?${query}` : ''}`

  navigateTo({
    url: url.toString(),
    clientSide: false,
  })
}

export default sendToKickout
