import { HistoricalOrder } from '@sh24/admin-api-js'
import groupBy from '../../utils/group-by'
import { camelize, titleize } from '../../utils/helpers'

const prepareTestData = (order: HistoricalOrder, translations: Record<string, string>) => {
  if (!order.tests) {
    return [{
      title: titleize(order.drugName || '')
        || translations[`orderCard.${camelize(order.orderType)}.defaultName`],
      description: '',
    }]
  }

  const parsedTests = order
    .tests
    .map((test) => {
      const [type, site] = test.split('_')

      if (type === 'hepatitis') return { type: `${type}${site.toUpperCase()}` }

      return {
        type: ['chlamydia', 'gonorrhoea'].includes(type) ? 'chlamydiaAndGonorrhoea' : type,
        ...(['chlamydia', 'gonorrhoea', 'syphilis'].includes(type) ? { site } : {}),
      }
    })

  const groupedTests = groupBy<{ type: string, site?: string }, string>(parsedTests, (test) => test.type)

  const preProcessedTests = Array.from(
    groupedTests,
    ([key, value]) => (
      {
        type: key,
        sites: Array.from(new Set(value.map((row) => row.site))),
      }
    ),
  )

  return preProcessedTests.map(
    ({ type, sites }) => (
      {
        title: translations[`orderCard.${camelize(order.orderType)}.${type}`]
          || translations[`orderCard.${camelize(order.orderType)}.defaultName`]
          || titleize(type),
        description: sites.map((site) => translations[`orderCard.${camelize(order.orderType)}.${site}`] || site).join(', '),
      }
    ),
  )
}

export default prepareTestData
