import { useContext } from 'react'
import styled from 'styled-components'
import ensurePrescriptionsLoaded from '../../../../utils/ensure-prescriptions-loaded'
import PrescriptionsPanelOrderDisplay from '../../../PrescriptionCards/prescriptions-panel-order-display'
import AccountContext from '../../../../contexts/account-context'
import useTranslations from '../../../../utils/use-translations'

const Centered = styled.div`
  text-align: center;
`

const PrescriptionsModule = () => {
  const { prescriptionsContext } = useContext(AccountContext)
  const { allPrescriptions, errors } = prescriptionsContext
  ensurePrescriptionsLoaded()

  const translations = useTranslations()

  if ((errors?.errors)) {
    return (
      <>
        <h2>An error occurred</h2>
        <p>
          There was a problem displaying your prescriptions. This has been reported
          and will be looked in to shortly. Please come back and try again
          later.
        </p>
      </>
    )
  }

  if (!allPrescriptions) {
    return (
      <Centered>
        <p>{translations['prescriptionCard.loadingPrescription']}</p>
      </Centered>
    )
  }

  if (allPrescriptions.prescriptions.length === 0) {
    return (
      <Centered>
        <p>{translations['prescriptionCard.noPrescriptions']}</p>
      </Centered>
    )
  }

  return (
    <div className="row row-centered">
      <div className="col col-12 col-md-6 col-lg-4">
        <PrescriptionsPanelOrderDisplay
          allPrescriptions={allPrescriptions}
        />
      </div>
    </div>
  )
}
export default PrescriptionsModule
