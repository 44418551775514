const validateTestAtHomeReportingMobile = async (ctx) => {
  const {
    phoneNumber,
    userKitCode,
    brand,
  } = ctx

  const data = JSON.stringify({
    mobileNumber: phoneNumber?.formattedNumber,
    brand,
    userKitCode,
  })

  const result = await fetch('/api/validate-test-at-home-reporting', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: data,
  })

  const parsed = await result.json()

  if (result.status !== 200) {
    const err = new Error('We\'re sorry something has gone wrong. Please ensure your mobile number and kit code are correct.')
    return Promise.reject(err)
  }

  const {
    validForReporting,
    sh24Uid: apiSh24Uid,
    userKitCode: apiUserKitCode,
  } = parsed || {}

  return {
    context: {
      validForReporting,
      sh24Uid: apiSh24Uid,
      userKitCode: apiUserKitCode,
    },
  }
}

export default validateTestAtHomeReportingMobile
