const navigator = ({ router } = {}) => ({
  url,
  options,
  clientSide = true,
}) => {
  (clientSide && router)
    ? router.push(url, undefined, options)
    : window.location.assign(url)
}

export default navigator
