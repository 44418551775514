type SetRecentHivRiskInput = Record<string, 'yes' | 'no'>

type SetRecentHivRiskOutput = {
  context: {
    recentHivRisk: boolean;
  };
};

const setRecentHivRisk = ({
  recentHivRiskAnalSex,
  recentHivRiskPartnerPositive,
  recentHivRiskNeedlestick,
  recentHivRiskInjectingDrugs,
}: SetRecentHivRiskInput): SetRecentHivRiskOutput => {
  const recentHivRisk = [
    recentHivRiskAnalSex,
    recentHivRiskPartnerPositive,
    recentHivRiskNeedlestick,
    recentHivRiskInjectingDrugs,
  ].some((answer) => answer === 'yes')

  return {
    context: {
      recentHivRisk,
    },
  }
}

export default setRecentHivRisk
