const clearAccountAnswers = (ctx) => {
  const userAccountsEnabled = ctx.user_accounts_enabled

  if (userAccountsEnabled) return { context: {} }

  return {
    context: {
      serviceUserAccountChoice: null,
      serviceUserEmailVerified: null,
      serviceUserPhoneNumberVerified: null,
      serviceUserFlow: null,
      username: null,
      email: null,
      phoneNumber: null,
      answersFromAccount: [],
    },
  }
}

export default clearAccountAnswers
