import { productToBasketItem } from '../helpers'

const updateExistingBasket = ({ products, basket }) => {
  const availableProductIds = products.map((p) => p.identifier)
  return basket.filter((item) => availableProductIds.includes(item.identifier))
}

const createNewBasket = ({ products }) => products
  .filter((product) => product.preselected || product.mandatory)
  .map((product) => productToBasketItem(product))

const loadBasket = async (ctx) => {
  const { basket: existingBasket } = ctx
  const basket = existingBasket?.length > 0
    ? updateExistingBasket(ctx)
    : createNewBasket(ctx)

  return {
    context: {
      basket,
    },
  }
}

export default loadBasket
