import { useContext } from 'react'
import styled from 'styled-components'

import { PrepSubscriptionData } from '@sh24/admin-api-js'
import { BreakpointHelpers } from '@sh24/ui-components'
import AccountContext from '../../../../contexts/account-context'
import ensurePrepSubscriptionLoaded from '../../../../utils/ensure-prep-subscription-loaded'
import StatusContainer from '../../../PrepDashboard/PrepDashboardStatus/status-container'
import OrderAvailabilityContainer from '../../../PrepDashboard/OrderAvailability/order-availability-container'
import useTranslations from '../../../../utils/use-translations'
import { ErrorData } from '../../../../types/api'

const { mobileOnly, tabletUp } = BreakpointHelpers

const Centered = styled.div`
  text-align: center;
`
const InnerContainer = styled.div`
    display: flex;
    flex-direction: column;
`

const StatusWrapper = styled.div`
    justify-content: center;
  width: 100%;
  margin: auto;

  ${tabletUp(`
  width: 80%;
      `)}

  ${mobileOnly(`
    `)}
`

const OrderAvailabilityWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;

  margin: auto;

  ${tabletUp(`
        width: 80%;
      `)}

  ${mobileOnly(`
    `)}
`

const PrepActionsModule = () => {
  const { prepSubscriptionContext } = useContext(AccountContext)
  const {
    prepSubscription,
    errors,
  }: {
    prepSubscription: PrepSubscriptionData | null,
    errors: ErrorData | null,
  } = prepSubscriptionContext

  ensurePrepSubscriptionLoaded()

  const translations = useTranslations()

  if ((errors?.errors)) {
    return (
      <>
        <h2>An error occurred</h2>
        <p>
          There was a problem displaying your prep subscription. This has been reported
          and will be looked in to shortly. Please come back and try again
          later.
        </p>
      </>
    )
  }

  if (!prepSubscription) {
    return (
      <Centered>
        <p>{translations['prepDashboard.loadingPrepSubscription']}</p>
      </Centered>
    )
  }

  if (!prepSubscription.prepSubscription) {
    return (
      <Centered>
        <p>{translations['prepDashboard.noPrepSubscription']}</p>
      </Centered>
    )
  }

  return (
    <div>
      <InnerContainer>
        <StatusWrapper>
          <StatusContainer lastOrders={prepSubscription.lastOrders} />
        </StatusWrapper>
        <OrderAvailabilityWrapper>
          <OrderAvailabilityContainer availableServices={prepSubscription.availableServices} />
        </OrderAvailabilityWrapper>
      </InnerContainer>
    </div>
  )
}
export default PrepActionsModule
