const modulesToReactComponents = ({ supportedModules, modules }) => {
  if (!modules) return null

  return modules.map((module) => {
    const { contentType } = module
    const Component = supportedModules[contentType]

    return Component ? (
      <Component
        key={module.id}
        {...module}
      />
    ) : null
  }).filter((Component) => Component)
}

export default modulesToReactComponents
