import airbrake from '../../../services/exception-notifier/client'
import verifyLuhnModN from '../helpers/luhn-mod-n-verifier'

const TOKEN_CHARACTER_SET = 'abcdefghjkmnpqrstuwxyz'

const sanitiseTestKitActivationCode = (activationCode) => activationCode.replace(/\s/g, '').toLowerCase()

const validCheckDigit = (activationCode) => verifyLuhnModN(TOKEN_CHARACTER_SET, activationCode)

const validActivationCodeFormat = (activationCode) => (
  /^[abcdefghjkmnpqrstuwxyz]{6}$/.test(activationCode)
)

const checkTestKitActivationCode = async (ctx) => {
  const activationCode = ctx.code
  const sanitisedCode = sanitiseTestKitActivationCode(activationCode)

  if (!validActivationCodeFormat(sanitisedCode)) {
    await airbrake.notify(`Test kit activation code ${sanitisedCode} is in incorrect format`)
    return {
      context: { activation_error: 'test_kit_not_found' },
    }
  }

  if (!validCheckDigit(sanitisedCode)) {
    await airbrake.notify(`Check digit is incorrect on test kit activation code ${sanitisedCode}`)
    return {
      context: { activation_error: 'test_kit_not_found' },
    }
  }

  const data = JSON.stringify({ activationCode: sanitisedCode })

  const result = await fetch('/api/user-activated-test-kit', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: data,
  })

  if (!result.ok) {
    const error = {
      message: 'Sorry, something went wrong. Please try again later. Note that your kit has not been activated.',
    }

    return Promise.reject(error)
  }

  const parsed = await result.json()

  return {
    context: {
      ...parsed?.data?.test_kit,
      activation_error: parsed?.data?.error,
    },
  }
}

export default checkTestKitActivationCode
