import { useContext, useEffect } from 'react'
import AccountContext from '../contexts/account-context'

const ensureOrdersLoaded = () => {
  const { refreshOrders } = useContext(AccountContext).ordersContext

  useEffect(() => {
    const updateOrders = async () => {
      if (refreshOrders) {
        await refreshOrders()
      }
    }

    updateOrders()
  }, [refreshOrders])
}

export default ensureOrdersLoaded
