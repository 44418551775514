import { BreakpointHelpers } from '@sh24/ui-components'
import styled from 'styled-components'
import PrepOrderStatus from './PrepOrderStatus/prep-order-status'
import StiTestOrderStatus from './StiTestOrderStatus/sti-test-order-status'

interface PrepOrderData {
  orderState: string
  updatedAt: Date
  trackingLink: string | null
}

interface StiTestOrderData {
  sh24Uid: string
  orderState: string
  updatedAt: Date
  consultation: {
    schedulingUrl: string | null
    state: string | null
  }
}

type StatusContainerTypes = {
  lastOrders: {
    prepOrder: PrepOrderData | null,
    stiTestOrder: StiTestOrderData | null,
  } | null
}

const { mobileOnly, tabletUp } = BreakpointHelpers

const Container = styled.div`
  ${({ theme }) => `
    margin-bottom: ${theme?.spacing?.md};
    display: flex;
    height: auto;

    ${mobileOnly(`
      flex-direction: column;
      gap: ${theme?.spacing?.sm};
    `)}

    ${tabletUp(`
      flex-direction: row;
      justify-content: center;
      gap: ${theme?.spacing?.md};

      > div {
        width: 40%;
      }
    `)}
  `}
`
const StatusContainer = ({ lastOrders }: StatusContainerTypes) => (
  <>
    {lastOrders && (
      <Container>
        <StiTestOrderStatus stiTestOrderData={lastOrders.stiTestOrder} />
        <PrepOrderStatus prepOrderData={lastOrders.prepOrder} />
      </Container>
    )}
  </>
)

export default StatusContainer
