const sendDataForAnalytics = async (ctx) => {
  const {
    dataAnalyticsToken,
    bloodTransfusionOrSurgery,
    piercingsOrTattoos,
    unexplainedLiverProblems,
    sharedDrugsEquipment,
    previousContactWithHepC,
    previouslyTestedForHepC,
    beenInPrison,
    countryOfBirth,
  } = ctx

  const dataForAnalytics = {
    bloodTransfusionOrSurgery,
    dataAnalyticsToken,
    piercingsOrTattoos,
    unexplainedLiverProblems,
    sharedDrugsEquipment,
    previousContactWithHepC,
    previouslyTestedForHepC,
    beenInPrison,
    countryOfBirth,
  }

  const res = await fetch('/api/upload-risk-assessment-data', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ data: dataForAnalytics }),
  })
  if (res.ok) return res
  const json = await res.json()
  throw new Error(JSON.stringify({ status: res.status, errors: json.errors }))
}

export default sendDataForAnalytics
