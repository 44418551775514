import { useRouter } from 'next/router'
import styled from 'styled-components'
import { ButtonLink, Accordion } from '@sh24/ui-components'
import { Prescription, PrepLastResults } from '@sh24/admin-api-js'
import Link from 'next/link'
import { formatDate } from '../../../utils/helpers'
import PrescriptionCard from '../../PrescriptionCards/prescription-card'
import useTranslations from '../../../utils/use-translations'
import InlineLink from '../../InlineLink/inline-link'

const AccordionContent = styled.div`
  ${({ theme }) => `
    margin-bottom: ${theme?.spacing?.md};
  `}
`

const SummaryContainer = styled.div`
  ${({ theme }) => `
    width: 100%;
    max-width: 400px;

    h2 {
      margin-bottom: ${theme?.spacing?.md}
    }
  `}
`

const ButtonWrapper = styled.div`
  ${({ theme }) => `
    margin-top: ${theme?.spacing?.md};
  `}
`

const LastPrescription = ({ lastPrescription } : { lastPrescription: Prescription | null }) => {
  const translations = useTranslations()
  const { locale } = useRouter()

  if (!lastPrescription) {
    return (
      <Accordion
        variant="alternate"
        title={translations['prepDashboard.yourLatestPrescription']}
        content={(
          <AccordionContent>
            {translations['prepDashboard.noPrescription']}
          </AccordionContent>
        )}
        closeText={translations['prepDashboard.close']}
      />
    )
  }

  const previewContent = (
    `${translations['prepDashboard.prescribed']} ${formatDate({ dateString: lastPrescription.prescribedAt, locale })}`
  )

  const prescriptionFormText = () => (lastPrescription.totalUnits > 1 ? 's' : '')

  const prescriptionSupplyLength = lastPrescription.courseLengthInMonths ? `${lastPrescription.courseLengthInMonths} ${translations['prescriptionCard.lengthOfSupply']} - ` : ''
  const prescriptionMethodString = `${lastPrescription.totalUnits} ${lastPrescription.form}${prescriptionFormText()}`

  const prescriptionDetails = [{
    title: lastPrescription.drugName,
    description: lastPrescription.ingredients,
    prescriptionQuantities: `${prescriptionSupplyLength} ${prescriptionMethodString}`,
  }]

  return (
    <Accordion
      variant="alternate"
      title={translations['prepDashboard.yourLatestPrescription']}
      previewContent={previewContent}
      content={(
        <PrescriptionCard
          items={prescriptionDetails}
          howToTake={translations['prescriptionCard.howToTake']}
          tagText={translations[`prescriptionCard.productType.${lastPrescription.productType}`]}
          tagIcon="tablet-capsule"
          sh24Uid={lastPrescription.sh24Uid}
          instructions={lastPrescription.instructions}
          prescribedAt={lastPrescription.prescribedAt}
        />
      )}
      closeText={translations['prepDashboard.close']}
    />
  )
}

const nextTestsDueText = (date: Date) => {
  const translations = useTranslations()
  const { locale } = useRouter()

  const nextTestsDue = translations['prepDashboard.nextTestsDue']

  return `${nextTestsDue} ${formatDate({ dateString: date, locale })}`
}

const LastResults = ({ lastResults, title } : { lastResults: PrepLastResults | null, title: string }) => {
  const translations = useTranslations()

  if (!lastResults) {
    return (
      <Accordion
        variant="alternate"
        title={title}
        content={(
          <AccordionContent>
            {translations['prepDashboard.noResults']}
          </AccordionContent>
        )}
        closeText={translations['prepDashboard.close']}
      />
    )
  }

  return (
    <Accordion
      variant="alternate"
      title={title}
      previewContent={lastResults.nextDue && nextTestsDueText(lastResults.nextDue)}
      content={(
        <AccordionContent>
          <Link href={`/account/results/${lastResults.sh24Uid}`}>Results</Link>
        </AccordionContent>
      )}
      closeText={translations['prepDashboard.close']}
    />
  )
}

const PrepDashboardSummary = ({
  prepSubscriptionId,
  lastPrescription = null,
  lastResults = null,
  lastResultsWithEgfr = null,
}: {
  prepSubscriptionId: number;
  lastPrescription?: Prescription | null;
  lastResults?: PrepLastResults | null;
  lastResultsWithEgfr?: PrepLastResults | null;
}) => {
  const translations = useTranslations()

  return (
    <SummaryContainer>
      <h2>{translations['prepDashboard.atAGlance']}</h2>
      <LastPrescription lastPrescription={lastPrescription} />
      <LastResults lastResults={lastResults} title={translations['prepDashboard.yourLatestTestResults']} />
      <LastResults lastResults={lastResultsWithEgfr} title={translations['prepDashboard.yourLatestEgfrResults']} />

      <ButtonWrapper>
        <ButtonLink variation="secondary" fullWidth iconPosition="none">
          <InlineLink url={`/account/prep/close/${prepSubscriptionId}`} text={translations['prepDashboard.cancelPrep']} />
        </ButtonLink>
      </ButtonWrapper>
    </SummaryContainer>
  )
}

export default PrepDashboardSummary
