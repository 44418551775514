const sendVerificationCode = async (ctx) => {
  const lastSentVerificationCodeTimestamp = sessionStorage.getItem('verificationCodeTimestamp')
  if (lastSentVerificationCodeTimestamp) {
    /* eslint-disable max-len */
    const secondsSinceLastCodeSent = Math.ceil((Date.now() - parseInt(lastSentVerificationCodeTimestamp, 10)) / 1000)
    const error = {
      message: ctx?.errorMessages?.mobileVerificationRetry,
    }
    if (secondsSinceLastCodeSent < 60) return Promise.reject(error)
  }
  const data = JSON.stringify({
    phoneNumber: ctx.phoneNumber.formattedNumber,
    countryCode: ctx.phoneNumber.countryCode,
  })

  const result = await fetch('/api/mobile-verification-codes', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: data,
  })

  const parsed = await result.json()
  if (result.status !== 200) return Promise.reject(parsed)

  sessionStorage.setItem('verificationCodeTimestamp', Date.now())

  const { verificationCipherText, verificationCode } = parsed
  return {
    context: {
      verificationCode,
      verificationCipherText,
    },
  }
}

export default sendVerificationCode
