import { Icon, OrderCard } from '@sh24/ui-components'
import useTranslations from '../../utils/use-translations'

type PrescriptionDetailsType = {
  title: string,
  description: string,
  prescriptionQuantities: string,
}

type PrescriptionCardType = {
  sh24Uid: string,
  prescribedAt: Date,
  instructions: string,
  items: Array<PrescriptionDetailsType>,
  tagText: string,
  tagIcon: string,
  howToTake: string
}

const PrescriptionCard = ({
  tagText,
  tagIcon,
  sh24Uid,
  items,
  prescribedAt,
  howToTake,
  instructions,
}: PrescriptionCardType) => {
  const translations = useTranslations()

  const prescribedString = () => {
    const statusText = translations['prescriptionCard.state.prescribed']
    const prescribedDate = prescribedAt.toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' })
    return `${statusText} ${prescribedDate}`
  }

  const orderIdText = `${translations['prescriptionCard.orderIdPrefix']} ${sh24Uid}`

  const howToTakeInfo = (
    <span className="mb-base">
      <Icon
        key="result-card-icon"
        name="info"
        height={20}
        width={20}
      />
      {howToTake}
    </span>
  )

  const instructionParagraphs = instructions.split('. ').map((inst) => (
    <p key={inst}>{inst}</p>
  ))

  return (
    <OrderCard
      tagText={tagText}
      tagIcon={tagIcon}
      items={items}
      status={prescribedString()}
      orderId={orderIdText}
      bottomSectionUpper={howToTakeInfo}
      bottomSectionLower={instructionParagraphs}
    />
  )
}

export default PrescriptionCard
