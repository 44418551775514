import React, { useMemo } from 'react'
import { Content, Grid } from '@sh24/ui-components'
import modulesToReactComponents from '../../../utils/modules-to-react-components'
import gridModules from './supported-grid-modules'
import GridContext from '../../../contexts/grid-context'
import { usePageContext } from '../../../contexts/page-context'
import useTranslations from '../../../utils/use-translations'
import documentToReactComponents from '../../../utils/document-to-react-components'

const GridModule = ({
  title,
  intro,
  width,
  mobileColumns,
  tabletColumns,
  desktopColumns,
  scrollable,
  inheritBackground,
  modules,
  autoPopulate,
}) => {
  const translations = useTranslations()
  const page = usePageContext()
  const renderHeader = page?.features?.moduleHeaders
  const headerContent = [
    (title ? <h2 key={title}>{title}</h2> : null),
    (intro ? documentToReactComponents(intro) : null),
  ].filter((item) => !!item)
  const contextProviderValue = useMemo(() => ({ inheritBackground }), [inheritBackground])

  return (
    <GridContext.Provider value={contextProviderValue}>
      {renderHeader && headerContent.length ? (
        <Content
          width="standard"
          textAlign="center"
          content={headerContent}
        />
      ) : null}
      <Grid
        width={width}
        mobileColumns={mobileColumns}
        tabletColumns={tabletColumns}
        desktopColumns={desktopColumns}
        scrollable={scrollable}
        auto={autoPopulate}
        ariaLabelLeft={translations['gridNavigation.previous']}
        ariaLabelRight={translations['gridNavigation.next']}
        loadMoreText={translations['gridNavigation.loadMore']}
      >
        {modulesToReactComponents({ supportedModules: gridModules, modules })}
      </Grid>
    </GridContext.Provider>
  )
}

export default GridModule
