import { useContext, useEffect } from 'react'
import AccountContext from '../contexts/account-context'

const ensureResultsLoaded = () => {
  const { refreshResults } = useContext(AccountContext).resultsContext

  useEffect(() => {
    const updateResults = async () => {
      if (refreshResults) {
        await refreshResults()
      }
    }

    updateResults()
  }, [refreshResults])
}

export default ensureResultsLoaded
