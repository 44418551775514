const calculateSexualPrefCode = ({ genderIdentity, sexualPreference }) => {
  const genderCode = {
    male: 'm',
    female: 'w',
  }
  const prefCode = {
    men: 'm',
    women: 'w',
    both: 'mw',
  }

  return `${genderCode[genderIdentity]}s${prefCode[sexualPreference]}`
}

export default calculateSexualPrefCode
