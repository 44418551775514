import { Timeline } from '@sh24/ui-components'
import documentToReactComponents from '../../../utils/document-to-react-components'

export type TimelineItem = { heading: string, body: object }

const TimelineModule = (
  { timelineItems }: {
    timelineItems: TimelineItem[],
  },
) => {
  const processedItems = timelineItems.map((item) => ({
    heading: item.heading,
    body: documentToReactComponents(item.body),
  }))

  return <Timeline items={processedItems} />
}

export default TimelineModule
