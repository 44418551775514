import { fetchUserAttributes } from 'aws-amplify/auth'
import { parsePhoneNumber } from 'libphonenumber-js/mobile'
import additionalPhoneCountryCodesForCountry from '../../../utils/additional-phone-country-codes-for-country'

export const getAcceptedCountryCodes = (codes) => {
  if (!Array.isArray(codes) || !codes.length) return []
  const allAdditionalCodes = codes.map((code) => (
    additionalPhoneCountryCodesForCountry(code)
  )).flat()

  return [
    ...codes,
    ...allAdditionalCodes,
  ]
}

const setUserContactDetails = async (ctx) => {
  const context = {
    email: null,
    phoneNumber: null,
    answersFromAccount: [],
  }

  if (ctx.serviceUserFlow === 'guest') {
    return { context }
  }

  const user = await fetchUserAttributes()

  if (user.email && user.email_verified) {
    context.email = user.email
    context.answersFromAccount.push('emailQuestion')
  }

  if (user.phone_number) {
    const acceptedCodes = getAcceptedCountryCodes(ctx.phone_regions)
    const parsedNumber = parsePhoneNumber(user.phone_number)
    const formattedNumber = parsedNumber.format('E.164')

    if (parsedNumber.isValid() && acceptedCodes.includes(parsedNumber.country)) {
      context.phoneNumber = {
        formattedNumber,
      }
      context.answersFromAccount.push('phoneNumberQuestion')
    }
  }

  return { context }
}

export default setUserContactDetails
