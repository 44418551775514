import React, { useState } from 'react'

import { useRouter } from 'next/router'
import useTranslations from '../../../utils/use-translations'

import documentToReactComponents from '../../../utils/document-to-react-components'
import AvailabilityCheckerForm from '../../Forms/AvailabilityCheckerForm/availability-checker-form'
import validateAvailabilityCheckerFields, { hasAvailabilityCheckerFieldErrors } from '../../../utils/validate-availability-checker-fields'
import type { Errors } from '../../../utils/validate-availability-checker-fields'

const AvailabilityCheckerModule = (
  {
    title,
    intro,
    resultsPage,
  } : {
    title: string,
    intro: object,
    resultsPage: string,
  }) => {
  const router = useRouter()
  const translations = useTranslations()

  const defaultErrors: Errors = { age: [], postcode: [] }
  const [errors, setErrors] = useState(defaultErrors)

  const document = documentToReactComponents(intro)

  const fieldErrors = (field: string) => errors[field]

  const handleSubmit = async (age: string, postcode: string) => {
    const validationErrors = validateAvailabilityCheckerFields({ age, postcode, translations })
    setErrors(validationErrors)

    if (hasAvailabilityCheckerFieldErrors(validationErrors)) return

    sessionStorage.setItem('availabilityCheckerAge', age)
    sessionStorage.setItem('availabilityCheckerPostcode', postcode)

    router.push(resultsPage)
  }

  return (
    <AvailabilityCheckerForm
      title={title}
      intro={document}
      onSubmit={handleSubmit}
      ageLabel={translations.age}
      postcodeLabel={translations.postcode}
      buttonLabel={translations.check}
      ageErrors={fieldErrors('age')}
      postcodeErrors={fieldErrors('postcode')}
    />
  )
}

export default AvailabilityCheckerModule
