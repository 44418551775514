import { useContext } from 'react'
import styled from 'styled-components'
import AccountContext from '../../../../contexts/account-context'
import ensurePrepSubscriptionLoaded from '../../../../utils/ensure-prep-subscription-loaded'
import PrepDashboardSummary from '../../../PrepDashboard/PrepDashboardSummary/prep-dashboard-summary'
import useTranslations from '../../../../utils/use-translations'

const Centered = styled.div`
  text-align: center;
`
const InnerContainer = styled.div`
    display: flex;
    flex-direction: column;
`

const OrderAvailabilityWrapper = styled.div`
  ${({ theme }) => `
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;

    margin: 0 auto ${theme?.spacing?.md};

  `}

`

const PrepSummaryModule = () => {
  const translations = useTranslations()

  const { prepSubscriptionContext } = useContext(AccountContext)
  const {
    prepSubscription,
    errors,
  } = prepSubscriptionContext

  ensurePrepSubscriptionLoaded()

  if ((errors?.errors)) {
    return (
      <>
        <h2>An error occurred</h2>
        <p>
          There was a problem displaying your prep subscription. This has been reported
          and will be looked in to shortly. Please come back and try again
          later.
        </p>
      </>
    )
  }

  if (!prepSubscription) {
    return (
      <Centered>
        <p>{translations['prepDashboard.loadingPrepSubscription']}</p>
      </Centered>
    )
  }

  if (!prepSubscription.prepSubscription) {
    return (
      <Centered>
        <p>{translations['prepDashboard.noPrepSubscription']}</p>
      </Centered>
    )
  }

  return (
    <div>
      <InnerContainer>
        <OrderAvailabilityWrapper>
          <PrepDashboardSummary
            prepSubscriptionId={prepSubscription?.prepSubscription?.id}
            lastPrescription={prepSubscription?.lastPrescription}
            lastResults={prepSubscription?.lastResults}
            lastResultsWithEgfr={prepSubscription?.lastResultsWithEgfr}
          />
        </OrderAvailabilityWrapper>
      </InnerContainer>
    </div>
  )
}
export default PrepSummaryModule
