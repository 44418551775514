const serviceMap = {
  'sti-test-kit': 'sti_testing',
  'emergency-contraception': 'emergency_contraception',
  'genital-diagnosis': 'genital_diagnosis',
  'combined-pill': 'oral_contraception',
  'progestogen-only-pill': 'oral_contraception',
  'contraceptive-injection': 'injectable',
  'contraceptive-patch': 'contraceptive_patch',
  'contraceptive-ring': 'contraceptive_ring',
  'start-prep': 'prep',
}

export default serviceMap
