import { serviceMap } from '../helpers'

/* eslint-disable camelcase */
const getConditionalQuestions = async (ctx) => {
  const {
    region_lookup,
    genderIdentity,
    genderIdentitySameAsBirthSex,
    ethnicity,
    sexualPreference,
  } = ctx

  const service = serviceMap[ctx.journey.slug] || ''
  const data = JSON.stringify({
    regionLookup: region_lookup,
    genderIdentity,
    genderIdentitySameAsBirthSex,
    ethnicity,
    sexualPreference,
    service,
  })

  const result = await fetch('/api/conditional-questions', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: data,
  })

  const parsed = await result.json()

  if (result.status !== 200) return Promise.reject(parsed)

  return {
    context: {
      ...parsed?.data?.conditional_questions,
    },
  }
}

export default getConditionalQuestions
