import React from 'react'
import { ContraceptionComparisonDetailTextOnlyCard } from '@sh24/ui-components'
import { Content } from '../types'
import documentToReactComponents from '../../../../utils/document-to-react-components'

interface CardWithTextOnlyProps {
  content: Content[]
}

const deriveMainContent = (mainContent: string | boolean | object) => {
  if (typeof mainContent === 'boolean') {
    return mainContent ? 'Yes' : 'No'
  }

  if (typeof mainContent === 'object') {
    return documentToReactComponents(mainContent)
  }

  return mainContent
}

const renderTexts = ({ id, mainContent }: Content) => (
  <div
    key={id}
    className="content-wrapper"
  >
    {deriveMainContent(mainContent)}
  </div>
)

const cardWithTextOnly = ({ content }: CardWithTextOnlyProps) => (
  <ContraceptionComparisonDetailTextOnlyCard
    texts={content.map(renderTexts)}
  />
)

export default cardWithTextOnly
