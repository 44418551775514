import getCurrentStep from './get-current-step'

const updateUrl = (ctx, navigateTo, query) => {
  const currentStep = getCurrentStep(ctx)
  if (currentStep?.contentType === 'service') return
  const { slug } = ctx?.journey || {}

  navigateTo({
    url: {
      pathname: '/orders/[slug]/[step]',
      query: {
        ...query,
        slug,
        step: currentStep.slug,
      },
    },
    options: { shallow: true },
  })
}

export default updateUrl
