import { Box, BreakpointHelpers, ButtonLink } from '@sh24/ui-components'
import styled from 'styled-components'
import InlineLink from '../../../InlineLink/inline-link'
import useTranslations from '../../../../utils/use-translations'

interface ReorderButtonsType {
  availableServices: Array<string>
}

interface ButtonGroupInfoType {
  content: string
  btnVariation: string
  url: string
  btnText: string
}

const { tabletUp } = BreakpointHelpers

const Container = styled.div<{ moreThanOneServiceAvailable: boolean }>`
  ${({ theme, moreThanOneServiceAvailable }) => `
    flex: 8;

    > *:first-child {
      height: 100%;
    }

    > div {
      padding-bottom: 0;
    }

    ${tabletUp(`
          > *:first-child {
            ${moreThanOneServiceAvailable ? 'height: 100%;' : 'height: inherit;'}
          }

          > div {
            ${moreThanOneServiceAvailable ? 'padding-bottom: 0;' : `padding-bottom: ${theme?.spacing?.md};`}
          }
        `)}
  `}
`

const Wrapper = styled.div`
  ${({ theme }) => `
    display: flex;
    flex-direction: column;
    gap: ${theme?.spacing?.md};
  `}
`

const ReorderButtonContainer = styled.div`
  ${({ theme }) => `
   display: flex;
   flex-direction: column;
   gap: ${theme?.spacing?.sm};
   padding-bottom: ${theme?.spacing?.md};
   border-bottom: 1px solid ${theme?.palette?.tertiary};
     &:last-child {
       border-bottom: none;
       padding-bottom: 0;
     }
     &:first-child {
       padding-top: 0;
     }
  `}
`

const CtaBtnContainer = styled.div`
  a {
   width: 100%;
   font-weight: 500;
  }
`

const ContentOuterContainer = styled.div`
  ${({ theme }) => `
     padding: ${theme?.spacing?.sm};
  `}
`

const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
`

const fetchButtonGroupInfo = (translations: object) => ({
  prepAndStiTestOrder: {
    btnVariation: 'brand',
    url: '/prep-reorder-prep-sti',
    btnText: translations['prepDashboard.orderAvailability.reorderButtonText.prepAndStiTestOrder' as keyof object],
    content: translations['prepDashboard.orderAvailability.content.prepAndStiTestOrder' as keyof object],
  },
  prepOrder: {
    btnVariation: 'brand',
    url: '/prep-reorder-prep',
    btnText: translations['prepDashboard.orderAvailability.reorderButtonText.prepOrder' as keyof object],
    content: translations['prepDashboard.orderAvailability.content.prepOrder' as keyof object],
  },
  stiTestOrder: {
    btnVariation: 'primary',
    url: '/prep-reorder-sti',
    btnText: translations['prepDashboard.orderAvailability.reorderButtonText.stiTestOrder' as keyof object],
    content: translations['prepDashboard.orderAvailability.content.stiTestOrder' as keyof object],
  },
})

const ReorderButtons = ({ availableServices }: ReorderButtonsType) => {
  const fetchAvailableServicesReorderButtonsInfo = () => {
    const translations = useTranslations()
    const buttonGroupInfo = fetchButtonGroupInfo(translations)

    if (availableServices.includes('prepOrder') && availableServices.includes('stiTestOrder')) {
      return [buttonGroupInfo.prepAndStiTestOrder, buttonGroupInfo.stiTestOrder]
    }

    const filteredAvailableServices: Array<ButtonGroupInfoType> = []
    availableServices.forEach((service) => {
      filteredAvailableServices.push(buttonGroupInfo[service as keyof object])
    })
    return filteredAvailableServices
  }

  const renderReorderButtons = () => (
    fetchAvailableServicesReorderButtonsInfo().map((group) => (
      <ReorderButtonContainer key={group.content}>
        <CtaBtnContainer>
          <ButtonLink variation={group.btnVariation} iconPosition="right">
            <InlineLink url={group.url} text={group.btnText} iconName="arrow-right" iconPosition="right" />
          </ButtonLink>
        </CtaBtnContainer>
        <ContentOuterContainer>
          <ContentWrapper>{group.content}</ContentWrapper>
        </ContentOuterContainer>
      </ReorderButtonContainer>
    ))
  )

  return (
    <Container moreThanOneServiceAvailable={availableServices.length > 1}>
      <Box
        backgroundColour="/primary200"
      >
        <Wrapper>
          {renderReorderButtons()}
        </Wrapper>
      </Box>
    </Container>
  )
}

export default ReorderButtons
