import { Content, Gallery } from '@sh24/ui-components'
import Image from '../../Image/image'
import { usePageContext } from '../../../contexts/page-context'
import documentToReactComponents from '../../../utils/document-to-react-components'

const GalleryModule = ({
  title,
  intro,
  outro,
  images,
  mobileColumns,
  tabletColumns,
  desktopColumns,
}) => {
  if (!images) return null
  const page = usePageContext()
  const renderContent = page?.features?.moduleHeaders
  const headerContent = [
    (title ? <h2 key={title}>{title}</h2> : null),
    (intro ? documentToReactComponents(intro) : null),
  ].filter((item) => !!item)
  const outroContent = outro ? documentToReactComponents(outro) : null

  const imageComponents = images.map((image) => (
    <Image
      key={image.id}
      image={image}
      layout="intrinsic"
      maxWidth="300"
      maxHeight="300"
    />
  ))

  return (
    <>
      {renderContent && headerContent.length ? (
        <Content
          width="standard"
          textAlign="center"
          content={headerContent}
        />
      ) : null}

      <Gallery
        images={imageComponents}
        mobileColumns={mobileColumns}
        tabletColumns={tabletColumns}
        desktopColumns={desktopColumns}
      />

      {renderContent && outroContent ? (
        <Content
          width="standard"
          textAlign="center"
          content={outroContent}
        />
      ) : null}
    </>
  )
}

export default GalleryModule
