const fetchECAvailability = async ({
  firstUnprotectedSexSincePeriod,
  lastUnprotectedSexSincePeriod,
  productId,
  unprotected,
}) => {
  const data = JSON.stringify({
    firstUnprotectedSexSincePeriod: unprotected === 'yes' ? firstUnprotectedSexSincePeriod : null,
    lastUnprotectedSexSincePeriod,
  })

  const result = await fetch('/api/emergency-contraception-availability', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: data,
  })

  const parsed = await result.json()
  if (result.status !== 200) return Promise.reject(parsed)

  const deliverableEC = parsed.filter((drug) => drug.deliverable)
  const effectiveEC = parsed.filter((drug) => drug.effective)
  const selectedECDeliverable = deliverableEC.some((drug) => drug.code.toString() === productId)
  const otherECDeliverable = deliverableEC.some((drug) => drug.code.toString() !== productId)
  const noECDeliverable = deliverableEC.length === 0
  const noECEffective = effectiveEC?.length === 0

  return {
    context: {
      ecAvailability: parsed,
      selectedECDeliverable,
      otherECDeliverable,
      noECDeliverable,
      noECEffective,
    },
  }
}

export default fetchECAvailability
