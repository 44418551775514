import { ServiceHighlight } from '@sh24/ui-components'
import Image from '../../Image/image'
import documentToReactComponents from '../../../utils/document-to-react-components'

const HighlightModule = ({
  id,
  contentType,
  image,
  content,
}) => {
  const document = documentToReactComponents(content)
  const HighlightImage = (
    <Image
      image={image}
      maxHeight={110}
      maxWidth={110}
      layout="intrinsic"
    />
  )
  return (
    <ServiceHighlight
      id={id}
      className={contentType}
      content={document}
      image={HighlightImage}
    />
  )
}

export default HighlightModule
