import brandCountryCodes from './brand-country-codes.json'
import orderTypes from './order-types.json'
import serviceNames from './service-names.json'
import orderDescriptions from './order-descriptions.json'

export const getBrandCountryCodes = (codes = brandCountryCodes) => (
  codes[process.env.NEXT_PUBLIC_SITE] || ['GB']
)

export const getOrderType = ({ types = orderTypes, slug }) => types[slug] || ''

export const getServiceName = ({ names = serviceNames, slug }) => names[slug] || ''

export const getOrderDescription = ({ descriptions = orderDescriptions, slug }) => descriptions[slug] || ''
