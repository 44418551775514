import {
  satisfiesConditions,
  getCurrentStep,
  getCurrentSection,
} from '../helpers'

export const shouldKickout = (ctx) => !!ctx.kickoutReason

export const isComplete = (ctx) => ctx.currentStep > ctx.journey.steps.length - 1

export const isValidService = (ctx) => {
  const step = getCurrentStep(ctx)
  const section = getCurrentSection(ctx)

  if (step?.contentType !== 'service') return false
  if (!satisfiesConditions(ctx, section.conditions)) return false
  if (!satisfiesConditions(ctx, step.conditions)) return false
  return true
}

export const isValidStep = (ctx) => {
  const step = getCurrentStep(ctx)
  const section = getCurrentSection(ctx)

  if (step?.contentType === 'service') return false
  if (!satisfiesConditions(ctx, section.conditions)) return false
  if (!satisfiesConditions(ctx, step.conditions)) return false
  return true
}
