import React from 'react'
import { ButtonLink, ContraceptionComparisonDetailLinkButtonsCard } from '@sh24/ui-components'
import { ContraceptionOption } from '../types'
import InlineLink from '../../../InlineLink/inline-link'

interface LinksProps {
  selectedOptions: ContraceptionOption[],
}

const renderWhereToGetLink = ({
  id, whereToGetButtonText, whereToGetLink,
}: ContraceptionOption) => (
  whereToGetButtonText && whereToGetLink && (
  <div key={id} className="button-outer-wrapper where-to-get-wrapper">
    <ButtonLink>
      <InlineLink
        url={whereToGetLink}
        text={whereToGetButtonText}
      />
    </ButtonLink>
  </div>
  )
)

const renderLearnMoreLink = ({ id, learnMoreButtonText, learnMoreLink }: ContraceptionOption) => (
  learnMoreButtonText && learnMoreLink && (
  <div key={id} className="button-outer-wrapper learn-more-wrapper">
    <ButtonLink>
      <InlineLink
        url={learnMoreLink}
        text={learnMoreButtonText}
      />
    </ButtonLink>
  </div>
  )
)

const links = ({ selectedOptions }: LinksProps) => (
  <ContraceptionComparisonDetailLinkButtonsCard
    learnMoreLinks={selectedOptions.map(renderLearnMoreLink)}
    whereToGetLinks={selectedOptions.map(renderWhereToGetLink)}
  />
)

export default links
