import { getProp } from '@sh24/contentful-service/utils'

export const getBoolean = (orderData, key, truthy = 'yes', falsy = 'no') => {
  switch (getProp(orderData, key)) {
    case truthy: return true
    case falsy: return false
    default: return null
  }
}

export const checkBoxValuePresence = (orderData, key, value, noOptionsSelectedValue = 'none_of_the_above') => {
  const options = getProp(orderData, key)
  if (!options || !options.length) return null
  if (options.includes(noOptionsSelectedValue)) return false
  if (options.includes(value)) return true

  return false
}

export const getDOB = (orderData, dobKey = 'dob') => {
  if (!orderData[dobKey]) return null
  const day = getProp(orderData[dobKey], 'dd')
  const month = getProp(orderData[dobKey], 'mm')
  const year = getProp(orderData[dobKey], 'yyyy')
  const dob = new Date(`${year}/${month}/${day}`)
  return [
    dob.getFullYear(),
    (`0${dob.getMonth() + 1}`).slice(-2),
    (`0${dob.getDate()}`).slice(-2),
  ].join('-')
}

export const getName = (orderData, firstNameKey = 'first_name', lastNameKey = 'last_name') => {
  const firstName = getProp(orderData, firstNameKey)
  const lastName = getProp(orderData, lastNameKey)

  return firstName && lastName ? `${firstName.trim()} ${lastName.trim()}` : ''
}

const getDrug = (orderData) => {
  const basket = getProp(orderData, 'basket')
  return basket?.find((item) => !item.isExtra) || null
}

export const getDrugConfiguration = ({ orderData, unitLabel = 'months_prescribed' }) => {
  const drugCode = getProp(orderData, 'drugCode')
  if (drugCode) return drugCode === 'donotknow' ? null : { drug_code: drugCode }

  const drug = getDrug(orderData)
  const identifier = drug?.selectedOption?.identifier || drug?.identifier
  const parts = identifier?.split('_') || []

  if (parts[0]?.includes('generic')) return null

  return {
    drug_code: parts[0],
    [unitLabel]: parts[1],
  }
}

export const getSelectedTests = ({ orderData }) => {
  const basket = getProp(orderData, 'basket')
  const selectedTests = basket
    ? basket.filter((item) => !item.isExtra).map((item) => item.identifier)
    : []

  return {
    selected_tests: selectedTests,
  }
}

export const getSexForEgfr = ({ orderData }) => {
  const basket = getProp(orderData, 'basket')
  if (basket?.find((item) => item.identifier === 'serum_creatinine')) {
    return { sex_for_egfr_only: getProp(orderData, 'sexForEgfr') || getProp(orderData, 'genderIdentity') }
  }

  return {}
}

export const getGenitals = ({ orderData }) => {
  const typeOfKit = getProp(orderData, 'typeOfKit')
  // our order journey asks whether the user wants a kit with a urine sample or a vaginal swab
  // admin currently expects to receive this answer as 'gentials' with values 'penis/vagina',
  // hence the need for this mapping
  const typeOfKitToGenitalsMapping = {
    urine: 'penis',
    swab: 'vagina',
  }

  // when the above mapping is not relevant, use the value from typeOfKit
  const genitals = typeOfKitToGenitalsMapping[typeOfKit] || typeOfKit
  return genitals ? { genitals } : {}
}
