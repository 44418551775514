import satisfiesConditions from './satisfies-conditions'

const sendToComplete = (ctx, navigateTo) => {
  const { journey } = ctx
  const { completionPage, conditionalCompletionPages } = journey
  const conditionalPage = conditionalCompletionPages?.find((dest) => satisfiesConditions(ctx, dest.conditions))

  navigateTo({
    url: conditionalPage?.url || completionPage.url,
    clientSide: false,
  })
}

export default sendToComplete
