import availabilityHandlers from './availabilityHandlers'
import airbrake from '../../../services/exception-notifier/client'

const setAvailableProducts = async (ctx, handlers = availabilityHandlers) => {
  const brand = process.env.NEXT_PUBLIC_SITE
  const handler = handlers[brand]

  if (!handler) {
    await airbrake.notify(`setAvailableProducts: No handler configured for ${brand}`)
    throw new Error('We\'re sorry. Something has gone wrong.')
  }

  const offer = await handler(ctx)

  if (!offer?.products?.length) {
    await airbrake.notify('setAvailableProducts failed to find any products')
    throw new Error('We\'re sorry. Something has gone wrong.')
  }

  return {
    context: {
      ...offer,
    },
  }
}

export default setAvailableProducts
