import Checkit from 'checkit'
import { getActiveItems } from '../helpers'
import {
  agreeTerms,
  basketHasMainItems,
  basketHasPrimaryItems,
  betweenInclusive,
  inPast,
  isValidDateOfBirth,
  lessThanEqualToField,
  earlierThanDate,
  laterThanDate,
  name,
  isMobilePhoneNumber,
  postcode,
  eircode,
  entriesGreaterThan,
  isEmail,
  doesNotContainEmoji,
  doesNotContainPhoneNumber,
  checkBoxSelectionRequired,
  isExclusiveSelection,
  greaterThan,
  greaterThanEqualTo,
  lessThan,
  lessThanEqualTo,
} from './validation-functions'
import customValidations from './custom-validations'
import withCustomMessages from './with-custom-messages'

Checkit.Validator.prototype.agreeTerms = agreeTerms
Checkit.Validator.prototype.basketHasMainItems = basketHasMainItems
Checkit.Validator.prototype.basketHasPrimaryItems = basketHasPrimaryItems
Checkit.Validator.prototype.betweenInclusive = betweenInclusive
Checkit.Validator.prototype.inPast = inPast
Checkit.Validator.prototype.isValidDateOfBirth = isValidDateOfBirth
Checkit.Validator.prototype.lessThanEqualToField = lessThanEqualToField
Checkit.Validator.prototype.earlierThanDate = earlierThanDate
Checkit.Validator.prototype.laterThanDate = laterThanDate
Checkit.Validator.prototype.name = name
Checkit.Validator.prototype.isMobilePhoneNumber = isMobilePhoneNumber
Checkit.Validator.prototype.postcode = postcode
Checkit.Validator.prototype.eircode = eircode
Checkit.Validator.prototype.entriesGreaterThan = entriesGreaterThan
Checkit.Validator.prototype.isEmail = isEmail
Checkit.Validator.prototype.doesNotContainEmoji = doesNotContainEmoji
Checkit.Validator.prototype.doesNotContainPhoneNumber = doesNotContainPhoneNumber
Checkit.Validator.prototype.checkBoxSelectionRequired = checkBoxSelectionRequired
Checkit.Validator.prototype.isExclusiveSelection = isExclusiveSelection
// overrides with better checking:
Checkit.Validator.prototype.greaterThan = greaterThan
Checkit.Validator.prototype.greaterThanEqualTo = greaterThanEqualTo
Checkit.Validator.prototype.lessThan = lessThan
Checkit.Validator.prototype.lessThanEqualTo = lessThanEqualTo

const validationService = (ctx) => {
  const {
    journey,
    currentStep,
    errorMessages,
    validationLabels,
  } = ctx
  const step = journey?.steps?.[currentStep]
  const questions = getActiveItems(step.questions, ctx)
  const validations = questions.map((question) => {
    const validationBuilder = customValidations[question.component] || null

    if (!validationBuilder && !question.validations) return null

    const all = validationBuilder ? validationBuilder(question.key, ctx) : {
      [question.key]: question.validations,
    }

    return all
  })

  const validationRules = validations
    .filter((validation) => !!validation)
    .reduce((prev, curr) => ({ ...prev, ...curr }), {})

  const checkit = Checkit(withCustomMessages(validationRules, errorMessages), {
    messages: errorMessages,
    labels: validationLabels,
  })

  return checkit.run(ctx)
}

export default validationService
