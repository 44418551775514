const setSafeguardingNeed = ({
  pressured,
  bribed,
  depression,
  olderOrYoungerPartner,
  scaredOrUncomfortable,
  assaulted,
  unsafeOrThreatened,
  fgm,
}) => {
  const safeguardingNeed = Array.from([
    pressured,
    bribed,
    depression,
    olderOrYoungerPartner,
    scaredOrUncomfortable,
    assaulted,
    unsafeOrThreatened,
    fgm,
  ]).some((answer) => answer === 'yes')

  return {
    context: {
      safeguardingNeed,
    },
  }
}

export default setSafeguardingNeed
