const highRiskGroups = [
  'msm',
  'msmw',
  'trans',
]

const highRiskTests = [
  'chlamydia_gonorrhoea_genital',
  'chlamydia_gonorrhoea_oral',
  'chlamydia_gonorrhoea_rectal',
  'hepatitis_b',
  'hiv',
  'syphilis',
]

const lowRiskTests = [
  'chlamydia_gonorrhoea_genital',
  'hiv',
  'syphilis',
]

const calculateRecommendedTests = ({ sexualPreferenceCode, genderIdentitySameAsBirthSex }) => {
  const riskCode = genderIdentitySameAsBirthSex !== 'yes'
    ? 'trans'
    : sexualPreferenceCode

  return highRiskGroups.includes(riskCode)
    ? highRiskTests
    : lowRiskTests
}

export default calculateRecommendedTests
