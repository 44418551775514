const verifyVerificationCode = async (ctx) => {
  const data = JSON.stringify({
    verificationCode: ctx.verification,
    verificationCipherText: ctx.verificationCipherText,
  })

  const result = await fetch('/api/verify-code', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: data,
  })

  const parsed = await result.json()
  if (result.status !== 200) return Promise.reject(parsed)

  const { verified } = parsed

  return {
    context: {
      verified,
    },
  }
}

export default verifyVerificationCode
