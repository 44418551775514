import {
  ButtonLink,
  Content,
  HelpSnippet,
} from '@sh24/ui-components'
import InlineLink from '../../InlineLink/inline-link'
import documentToReactComponents from '../../../utils/document-to-react-components'

const buildHelpSnippet = ({
  id,
  title,
  content,
  link,
  accordion,
}) => {
  const document = documentToReactComponents(content)
  const CallToAction = (link?.text && link?.url) ? (
    <ButtonLink variation="text" animation="shiftRight" iconPosition="right">
      <InlineLink
        {...link}
        iconName="arrow-right"
        context="help-snippet"
      />
    </ButtonLink>
  ) : null

  return (
    <HelpSnippet
      key={id}
      title={title}
      content={document}
      cta={CallToAction}
      accordion={accordion}
    />
  )
}

const HelpSnippetsModule = ({
  title,
  intro,
  snippets,
}) => {
  const headerContent = [
    (title ? <h2 key={title}>{title}</h2> : null),
    (intro ? documentToReactComponents(intro) : null),
  ].filter((item) => !!item)

  return (
    <>
      {(headerContent.length > 0) && <Content width="standard" textAlign="left" content={headerContent} />}
      {snippets.map(buildHelpSnippet)}
    </>
  )
}

export default HelpSnippetsModule
