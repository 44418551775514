import { createContext } from 'react'
import { useInterpret } from '@xstate/react'
import { useRouter } from 'next/router'
import journeyMachine from '../state/journey-machine'
import * as actions from '../state/actions'
import * as guards from '../state/guards'
import validationService from '../validation/validation-service'
import serviceProxy from '../services/service-proxy'
import useTranslations from '../../../utils/use-translations'
import lookupTranslations from '../../../utils/lookup-translations'
import navigator from '../../../services/navigator'
import {
  createSessionKey,
  sendToKickout,
  sendToComplete,
  updateUrl,
} from '../helpers'
import {
  getBrandCountryCodes,
  getOrderType,
  getOrderDescription,
} from '../config'

export const JourneyContext = createContext({})

export const JourneyContextProvider = ({ journey, discountSettings, children }) => {
  const router = useRouter()
  const { slug } = journey

  const navigateTo = navigator({ router })
  const translations = useTranslations()
  const sessionKey = createSessionKey({ slug })
  const initialState = {
    ...journeyMachine.initialState,
    context: {
      ...journeyMachine.initialState.context,
      locale: router.locale,
      currency: 'GBP',
      brand: process.env.NEXT_PUBLIC_BRAND_OVERRIDE || process.env.NEXT_PUBLIC_SITE,
      phone_regions: getBrandCountryCodes(),
      orderType: getOrderType({ slug }),
      orderTypeDescription: getOrderDescription({ slug }),
      sessionKey,
      journey,
      discountSettings,
      errorMessages: lookupTranslations({ translations, lookup: 'errors.' }),
      validationLabels: lookupTranslations({ translations, lookup: 'validationLabels.' }),
    },
  }

  const service = useInterpret(journeyMachine, {
    state: initialState,
    services: {
      serviceProxy,
      validationService,
    },
    guards,
    actions: {
      ...actions,
      updateUrl: (ctx) => updateUrl(ctx, navigateTo, router.query),
      kickoutRedirect: (ctx) => sendToKickout(ctx, navigateTo),
      completeRedirect: (ctx) => sendToComplete(ctx, navigateTo),
    },
  })

  return (
    <JourneyContext.Provider value={service}>
      {children}
    </JourneyContext.Provider>
  )
}
