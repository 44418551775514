const validateTestAtHomeReportingUid = async (ctx) => {
  const {
    userKitCode,
    brand,
    sh24Uid,
  } = ctx

  const data = JSON.stringify({
    sh24Uid,
    brand,
    userKitCode,
  })

  if (!sh24Uid && !userKitCode) {
    return {
      context: {
        mobileCheckRequired: true,
      },
    }
  }

  const result = await fetch('/api/validate-test-at-home-reporting', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: data,
  })

  const parsed = await result.json()

  if (result.status !== 200) {
    return {
      context: {
        mobileCheckRequired: true,
        // clear values as they may be incorrect
        userKitCode: null,
        sh24Uid: null,
      },
    }
  }

  const {
    phoneNumberRedacted,
    validForReporting,
    sh24Uid: apiSh24Uid,
    userKitCode: apiUserKitCode,
  } = parsed || {}

  return {
    context: {
      phoneNumberRedacted,
      validForReporting,
      sh24Uid: apiSh24Uid,
      userKitCode: apiUserKitCode,
    },
  }
}

export default validateTestAtHomeReportingUid
