import getCurrentStep from './get-current-step'
import getCurrentSection from './get-current-section'
import satisfiesConditions from './satisfies-conditions'
/**
 * A function to recursively step back through journey steps
 * until one with questions is found. Then return the key of the first question.
 * Intended to help services report error messages back to the UI.
 *
 * @param {number} stepIndex - the current index
 * @param {object} journey - the journey config
 * @returns {string}
 */
const getQuestionKeyForService = (ctx) => {
  const prevIndex = ctx.currentStep - 1

  if (prevIndex < 0) return 'notFound'

  const prevCtx = {
    ...ctx,
    currentStep: prevIndex,
  }

  const step = getCurrentStep(prevCtx)
  const section = getCurrentSection(prevCtx)

  if (
    step?.contentType === 'service'
    || !satisfiesConditions(prevCtx, section.conditions)
    || !satisfiesConditions(prevCtx, step.conditions)
  ) {
    return getQuestionKeyForService(prevCtx)
  }

  return step.questions[0]?.key || 'notFound'
}

export default getQuestionKeyForService
