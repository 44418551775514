import { useRouter } from 'next/router'
import { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Button, ErrorMessages } from '@sh24/ui-components'
import documentToReactComponents from '../../../utils/document-to-react-components'
import airbrake from '../../../services/exception-notifier/client'
import formatPostcode from '../../../utils/format-postcode'

import ResultsButton from './results-button'

import { compact, toSmartLowerCase } from '../../../utils/helpers'
import Service, { AvailableService } from './service'
import validateAvailabilityCheckerFields, { hasAvailabilityCheckerFieldErrors } from '../../../utils/validate-availability-checker-fields'
import type { Errors } from '../../../utils/validate-availability-checker-fields'
import useTranslations from '../../../utils/use-translations'

type Theme = {
  palette: {
    primary200: string,
    tertiary200: string,
  },
  spacing: {
    sm: string,
    md: string,
    lg: string,
  }
}

const AvailableServices = styled.div`
  ${({ theme, type = 'other' }: { theme: Theme, type?: string}) => {
    const backgrounds = {
      services: theme?.palette?.primary200,
      fettle: '#FDF7E6',
      other: theme?.palette?.tertiary200,
    } as { [key: string]: string }

    return `
      border-radius: ${theme?.spacing?.sm};
      padding: 0 ${theme?.spacing?.md};
      background: ${backgrounds[type]};
      margin-bottom: ${theme?.spacing?.sm};

      + h3 {
        margin-top: ${theme?.spacing?.lg}
      }
    `
  }}
`

const BackButtonContainer = styled.div`
  ${({ theme }) => `
    margin-bottom: ${theme?.spacing?.md}
  `}
`

const AvailabilityCheckerResultsModule = ({
  loadingDoc,
  errorDoc,
  searchSummary,
  happyPathHeading,
  happyPathIntro,
  happyPathAlternativesHeading,
  mixedResultsAlternativesHeading,
  mixedResultsAlternativesIntroDoc,
  stiTestingTitle,
  stiTestingButtons,
  emergencyContraceptionTitle,
  emergencyContraceptionButtons,
  contraceptionTitle,
  combinedPillButton,
  progestogenOnlyPillButton,
  pillComparisonLink,
  contraceptivePatchButton,
  contraceptiveRingButton,
  contraceptiveInjectionButton,
  genitalDiagnosisTitle,
  genitalDiagnosisButtons,
  kickoutHeading,
  kickoutIntroDoc,
  kickoutAlternativesHeading,
  hcrgIntroDoc,
  hcrgButtons,
  fettleIntroDoc,
  fettleButtons,
  localClinicIntroDoc,
  localClinicButtons,
} : {
  loadingDoc: object,
  errorDoc: object,
  searchSummary: string,
  happyPathHeading: string,
  happyPathIntro: string,
  happyPathAlternativesHeading: string,
  mixedResultsAlternativesHeading: string,
  mixedResultsAlternativesIntroDoc: object,
  stiTestingTitle: string,
  stiTestingButtons: { url: string, text: string }[],
  emergencyContraceptionTitle: string,
  emergencyContraceptionButtons: { url: string, text: string }[],
  contraceptionTitle: string,
  combinedPillButton: { url: string, text: string },
  progestogenOnlyPillButton: { url: string, text: string },
  pillComparisonLink: { url: string, text: string },
  contraceptivePatchButton: { url: string, text: string },
  contraceptiveRingButton: { url: string, text: string },
  contraceptiveInjectionButton: { url: string, text: string },
  genitalDiagnosisTitle: string,
  genitalDiagnosisButtons: { url: string, text: string }[],
  kickoutHeading: string,
  kickoutIntroDoc: object,
  kickoutAlternativesHeading: string,
  hcrgIntroDoc: object,
  hcrgButtons: { url: string, text: string }[],
  fettleIntroDoc: object,
  fettleButtons: { url: string, text: string }[],
  localClinicIntroDoc: object,
  localClinicButtons: { url: string, text: string }[],
}) => {
  const loading = documentToReactComponents(loadingDoc)
  const error = documentToReactComponents(errorDoc)
  const mixedResultsAlternativesIntro = documentToReactComponents(mixedResultsAlternativesIntroDoc)
  const fettleIntro = documentToReactComponents(fettleIntroDoc)
  const kickoutIntro = documentToReactComponents(kickoutIntroDoc)
  const hcrgIntro = documentToReactComponents(hcrgIntroDoc)
  const localClinicIntro = documentToReactComponents(localClinicIntroDoc)

  const translations = useTranslations()

  const router = useRouter()

  // const { age, postcode } = router.query as { age: string, postcode: string }
  const [age, setAge] = useState('')
  const [postcode, setPostcode] = useState('')
  const [pageState, setPageState] = useState('loading')
  const [results, setResults] = useState({ data: { frontend_site: null, available_services: [] } })
  const [errors, setErrors] = useState<Errors>({ age: [], postcode: [] })

  const frontendSite = results?.data?.frontend_site as string | null
  const availableServices = results?.data?.available_services as string[]

  const fetchAvailableServices = async (ageValue: string, postcodeValue: string) => {
    const data = new URLSearchParams({ age: ageValue, postcode: postcodeValue })
    const url = `/api/available-services?${data}`
    const response = await fetch(url)
    const jsonBody = await response.json()

    return { status: response.status, json: jsonBody }
  }

  useEffect(() => {
    setPageState('loading')

    const sessionAge = sessionStorage.getItem('availabilityCheckerAge') || ''
    const sessionPostcode = sessionStorage.getItem('availabilityCheckerPostcode') || ''
    setAge(sessionAge)
    setPostcode(sessionPostcode)

    const validationErrors = validateAvailabilityCheckerFields({
      age: sessionAge,
      postcode: sessionPostcode,
      translations,
    })
    if (hasAvailabilityCheckerFieldErrors(validationErrors)) {
      setPageState('validationError')
      setErrors(validationErrors)
      return
    }

    const queryApi = async () => {
      const { status, json } = await fetchAvailableServices(sessionAge, sessionPostcode)

      if (status !== 200) {
        airbrake.notify({ status, json })
        setPageState('error')
      } else {
        setResults(json)
        setPageState('complete')
      }
    }
    queryApi()
  }, [])

  const serviceAvailable = (service: string) => availableServices?.includes(service)

  const anyServicesAvailable = () => (
    frontendSite === process.env.NEXT_PUBLIC_SITE && availableServices.length > 0
  )

  const allServicesAvailable = () => (
    // allexcept genital diagnosis, as not offered by fettle.
    [
      'sti_testing',
      'oral_contraception',
      'contraceptive_ring',
      'contraceptive_patch',
      'injectable',
      'emergency_contraception',
    ].every((service) => availableServices.includes(service))
  )

  const contraceptionAvailable = () => (
    serviceAvailable('oral_contraception')
    || serviceAvailable('contraceptive_ring')
    || serviceAvailable('contraceptive_patch')
    || serviceAvailable('injectable')
  )

  const renderSearchSummary = () => {
    const formattedPostcode = formatPostcode(postcode)

    return searchSummary
      .replace('<age>', age)
      .replace('<postcode>', formattedPostcode)
  }

  const renderReadableServiceList = () => {
    const services : string[] = compact([
      serviceAvailable('sti_testing') ? toSmartLowerCase(stiTestingTitle) : null,
      serviceAvailable('emergency_contraception') ? toSmartLowerCase(emergencyContraceptionTitle) : null,
      contraceptionAvailable() ? toSmartLowerCase(contraceptionTitle) : null,
      serviceAvailable('genital_diagnosis') ? toSmartLowerCase(genitalDiagnosisTitle) : null,
    ])

    const lastService : string | undefined = services.pop()

    if (!lastService) return ''

    const readableServiceList : string = (services.length === 0)
      ? lastService
      : [services.join(', '), lastService].join(' and ')

    return happyPathIntro.replace('<services>', readableServiceList)
  }

  const renderIntro = () => {
    if (!availableServices?.length) return null

    return (
      <>
        <h2>{happyPathHeading}</h2>
        <p>{renderReadableServiceList()}</p>
      </>
    )
  }

  const renderContraceptionButtons = (): React.ReactNode => (
    <>
      {serviceAvailable('oral_contraception') && (
        <>
          {combinedPillButton && (
            <ResultsButton
              url={combinedPillButton.url}
              text={combinedPillButton.text}
            />
          )}
          {progestogenOnlyPillButton && (
            <ResultsButton
              url={progestogenOnlyPillButton.url}
              text={progestogenOnlyPillButton.text}
            />
          )}
          {pillComparisonLink && (
            <ResultsButton
              url={pillComparisonLink.url}
              text={pillComparisonLink.text}
              arrow={false}
              variation="info"
            />
          )}
        </>
      )}

      {serviceAvailable('contraceptive_patch') && contraceptivePatchButton && <ResultsButton url={contraceptivePatchButton.url} text={contraceptivePatchButton.text} />}

      {serviceAvailable('contraceptive_ring') && contraceptiveRingButton && <ResultsButton url={contraceptiveRingButton.url} text={contraceptiveRingButton.text} />}

      {serviceAvailable('injectable') && contraceptiveInjectionButton && <ResultsButton url={contraceptiveInjectionButton.url} text={contraceptiveInjectionButton.text} />}
    </>
  )

  const renderServices = () => (
    <>
      {renderIntro()}

      <AvailableServices type="services">
        {serviceAvailable('sti_testing') && <Service intro={<h3>{stiTestingTitle}</h3>} buttons={stiTestingButtons} />}
        {serviceAvailable('emergency_contraception') && <Service intro={<h3>{emergencyContraceptionTitle}</h3>} buttons={emergencyContraceptionButtons} />}
        {contraceptionAvailable() && (
          <Service intro={<h3>{contraceptionTitle}</h3>}>
            {renderContraceptionButtons()}
          </Service>
        )}

        {serviceAvailable('genital_diagnosis') && <Service intro={<h3>{genitalDiagnosisTitle}</h3>} buttons={genitalDiagnosisButtons} />}
      </AvailableServices>
    </>
  )

  const renderHcrg = () => (
    frontendSite === 'hcrg' && (
      <AvailableServices>
        <Service intro={hcrgIntro} buttons={hcrgButtons} buttonProps={{ variation: 'primary' }} />
      </AvailableServices>
    )
  )

  const renderFettle = () => (
    parseInt(age, 10) >= 16 && (
      <AvailableServices type="fettle">
        <AvailableService>
          <div>{fettleIntro}</div>
          <div>
            {fettleButtons.map(({ url, text }, idx) => <ResultsButton url={url} text={text} variation={idx % 2 === 0 ? 'primary' : 'secondary'} />)}
          </div>
        </AvailableService>
      </AvailableServices>
    )
  )

  const renderLocalClinicPharmacy = () => (
    <AvailableServices>
      <Service intro={localClinicIntro} buttons={localClinicButtons} buttonProps={{ arrow: false, variation: 'primary' }} />
    </AvailableServices>
  )

  const renderAlternateServices = () => (
    <>
      {renderHcrg()}
      {renderFettle()}
      {renderLocalClinicPharmacy()}
    </>
  )

  const renderHappyPath = () => (
    <>
      {renderServices()}

      {allServicesAvailable() ? (
        <h3>{happyPathAlternativesHeading}</h3>
      ) : (
        <>
          <h3>{mixedResultsAlternativesHeading}</h3>
          {mixedResultsAlternativesIntro}
        </>
      )}

      {renderAlternateServices()}
    </>
  )

  const renderKickout = () => (
    <>
      <h2>{kickoutHeading}</h2>
      {kickoutIntro}
      <h3>{kickoutAlternativesHeading}</h3>

      {renderAlternateServices()}
    </>
  )

  const handleBack = (e: React.SyntheticEvent) => {
    e.preventDefault()
    router.back()
  }

  return (
    <>
      <div className="row row-centered">
        <div className="col col-12 col-md-8">
          <div>
            <BackButtonContainer>
              <Button onClick={handleBack} text="Back" variation="text" iconPosition="left" iconName="arrow-left" />
            </BackButtonContainer>

            {pageState === 'loading' && (
              <>
                {loading}
              </>
            )}

            {pageState === 'validationError' && (
              <ErrorMessages errors={Object.values(errors).flat()} />
            )}

            {pageState === 'error' && (
              <>
                {error}
              </>
            )}

            {pageState === 'complete' && (
              <>
                <p>{renderSearchSummary()}</p>
                {anyServicesAvailable()
                  ? renderHappyPath()
                  : renderKickout()}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default AvailabilityCheckerResultsModule
