import { CancelAppointmentResponseData } from '@sh24/admin-api-js'
import { errorMessage } from '../../utils/api-utils'
import { ErrorData } from '../../types/api'

const cancelAppointment = async (token: string | null): Promise<CancelAppointmentResponseData|ErrorData> => {
  try {
    const response = await fetch(
      '/api/v2/consultation-booking/cancel-appointment',
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token }),
      },
    )

    const parsedResponse = await response.json()

    return parsedResponse
  } catch (error) {
    return { errors: [{ message: errorMessage(error) }] }
  }
}

export default cancelAppointment
