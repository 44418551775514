import React from 'react'

const SEO = ({
  seo,
  Head,
  url,
}) => {
  if (!seo) return null
  const {
    title,
    description,
    schema,
    keywords,
    robots,
    og,
    canonicalSite,
    includeHreflangTags,
  } = seo
  let canonicalUrl = null

  if (typeof window !== 'undefined' && url) {
    const canonicalOrigin = canonicalSite === process.env.NEXT_PUBLIC_SITE
      ? window?.location?.origin
      : 'https://sh24.org.uk'

    canonicalUrl = `${canonicalOrigin}${url}`
  }

  const hrefLangTags = () => {
    const sh24Url = `https://sh24.org.uk${url}`
    const sh24IrelandUrl = `https://sh24.ie${url}`

    return (
      <>
        <link rel="alternate" href={sh24IrelandUrl} hrefLang="en-ie" />
        <link rel="alternate" href={sh24Url} hrefLang="en" />
        <link rel="alternate" href={sh24Url} hrefLang="x-default" />
      </>
    )
  }

  return (
    <Head>
      <title>{title}</title>
      {canonicalUrl && <link key="canonical" rel="canonical" href={canonicalUrl} />}
      {description && <meta name="description" content={description} />}
      {robots && <meta name="robots" content={robots} />}
      {keywords?.length > 0 && <meta name="keywords" content={keywords.join(', ')} />}
      {og?.title && <meta name="og:title" content={og.title} />}
      {og?.description && <meta name="og:description" content={og.description} />}
      {og?.image?.src && <meta name="og:image" content={og.image.src} />}
      {og?.image?.alt
        && <meta name="og:image:alt" content={og.image.alt} />}
      {schema && (
        /* eslint-disable react/no-danger */
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(schema, null, 2),
          }}
        />
      )}
      {includeHreflangTags && hrefLangTags()}
    </Head>
  )
}

export default React.memo(SEO)
