import React from 'react'
import { ContraceptionComparisonDetailTextWithHeadingCard } from '@sh24/ui-components'
import documentToReactComponents from '../../../../utils/document-to-react-components'
import { Content } from '../types'

interface CardWithHeadingProps {
  heading: string,
  content: Content[],
  background: boolean,
}

const deriveMainContent = (mainContent: string | boolean | object) => {
  if (typeof mainContent === 'boolean') {
    return mainContent ? 'Yes' : 'No'
  }

  if (typeof mainContent === 'object') {
    return documentToReactComponents(mainContent)
  }

  return mainContent
}

const renderContent = ({ id, mainContent }: Content) => (
  <div key={id} className="content-wrapper">
    {deriveMainContent(mainContent)}
  </div>
)

const cardWithHeading = ({ heading, content, background }: CardWithHeadingProps) => (
  <ContraceptionComparisonDetailTextWithHeadingCard
    heading={heading}
    content={content.map(renderContent)}
    background={background}
  />
)

export default cardWithHeading
