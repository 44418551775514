import styled from 'styled-components'
import { BreakpointHelpers } from '@sh24/ui-components'
import Information from './Information/information'
import useTranslations from '../../../utils/use-translations'
import ReorderButtons from './ReorderButtons/reorder-buttons'

interface OrderAvailabilityContainerType {
  availableServices: {
    prepOrder: boolean
    stiTestOrder: boolean
  } | null
}

const { mobileOnly, tabletUp } = BreakpointHelpers

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;

    ${tabletUp(`
        width: 80%;
      `)}


    ${mobileOnly(`
      flex-direction: column;
    `)}

    h3 {
      text-align: center;
    }
`

const OuterWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const CtaAndInfoWrapper = styled.div`
  ${({ theme }) => `
    display: flex;
    justify-content: center;
    height: auto;
    gap: ${theme?.spacing?.md};

    ${tabletUp(`
        flex-direction: row;
      `)}

    ${mobileOnly(`
      flex-direction: column;
    `)}
  `}
`

const OrderAvailabilityContainer = ({ availableServices }: OrderAvailabilityContainerType) => {
  const translations = useTranslations()

  const extractAvailableServices = (availableServicesToOrder: object) => (
    Object
      .keys(availableServicesToOrder)
      .filter((service) => availableServicesToOrder[service as keyof boolean])
  )

  const servicesAvailableToOrder = (availableServices && extractAvailableServices(availableServices)) || []
  const headingText = translations['prepDashboard.orderAvailability.availableToOrderHeading']

  return (
    <Container>
      <OuterWrapper>
        {servicesAvailableToOrder && servicesAvailableToOrder.length > 0 && (
          <h3>{headingText}</h3>
        )}
        <CtaAndInfoWrapper>
          {servicesAvailableToOrder && servicesAvailableToOrder.length > 0 && (
            <ReorderButtons
              availableServices={servicesAvailableToOrder}
            />
          )}
          <Information
            servicesAvailableToOrder={servicesAvailableToOrder.length > 0}
          />
        </CtaAndInfoWrapper>
      </OuterWrapper>
    </Container>
  )
}

export default OrderAvailabilityContainer
