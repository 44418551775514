import Checkit from 'checkit'

const createCheckitError = (field, message) => {
  const error = new Checkit.Error(message)
  const fieldError = new Checkit.FieldError(message)
  const validationError = new Checkit.ValidationError(message)
  error.errors[field] = fieldError
  fieldError.errors.push(validationError)
  return error
}

export default createCheckitError
