import { buildProduct } from '../../helpers'
import {
  calculateRecommendedTests,
  calculateSexualPrefCode,
} from '../../calculators'

const stiStrategy = (ctx) => {
  const { products: journeyProducts } = ctx.journey
  const {
    selected,
    hivDiagnosed,
    hivDiagnosedConfirmation,
    hepBVaccinated,
    genderIdentitySameAsBirthSex,
    currency,
    locale,
  } = ctx

  const sexualPreferenceCode = calculateSexualPrefCode(ctx)
  const selectedArr = selected
    ? selected.split(',')
    : calculateRecommendedTests({ sexualPreferenceCode, genderIdentitySameAsBirthSex })
  const hivPositive = hivDiagnosed === 'yes' && hivDiagnosedConfirmation === 'yes'

  return journeyProducts
    .filter((product) => {
      if (product.isExtra) return false
      if (product.identifier === 'hiv' && hivPositive) return false
      if (product.identifier === 'hepatitis_b' && hepBVaccinated === 'yes') return false
      return true
    }).map((product) => buildProduct({
      product,
      mandatory: false,
      preselected: selectedArr.includes(product.identifier),
      currency,
      locale,
    }))
}

const productStrategy = (ctx) => {
  const { products: journeyProducts } = ctx.journey
  const {
    productId,
    selected,
    currency,
    locale,
  } = ctx
  const products = journeyProducts.filter((product) => !product.isExtra)
  const selectedProducts = !productId && products.length === 1
    ? products
    : products.filter((product) => product.identifier === productId)

  return selectedProducts.map((product) => buildProduct({
    product,
    mandatory: true,
    preselected: true,
    selectedOption: selected,
    currency,
    locale,
  }))
}

const extrasStrategy = (ctx) => {
  const { products: journeyProducts } = ctx.journey
  const { currency, locale } = ctx
  return journeyProducts
    .filter((product) => product.isExtra)
    .map((product) => buildProduct({
      product,
      mandatory: false,
      preselected: false,
      currency,
      locale,
    }))
}

const fettleProductAvailibilityHandler = async (ctx) => {
  const { orderType } = ctx
  const products = orderType === 'sti_test_order'
    ? stiStrategy(ctx)
    : productStrategy(ctx)

  const extras = extrasStrategy(ctx)

  return {
    products: [
      ...products,
      ...extras,
    ],
  }
}

export default fettleProductAvailibilityHandler
