const calculatePromoDiscount = ({ price, discount }) => {
  const {
    monetary_amount: monetary,
    percentage_amount: percentage,
  } = discount

  if (!monetary && !percentage) return 0

  return -(monetary || (price * (percentage / 100)))
}

export default calculatePromoDiscount
