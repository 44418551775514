import React from 'react'
import { ContraceptionComparisonDetailTextWithBubbleCard } from '@sh24/ui-components'
import { Content } from '../types'
import documentToReactComponents from '../../../../utils/document-to-react-components'

interface CardWithHeadingBubbleProps {
  heading: string,
  content: Content[],
  background: boolean
}

const deriveMainContent = (mainContent: string | boolean | object) => {
  if (typeof mainContent === 'boolean') {
    return mainContent ? 'Yes' : 'No'
  }

  if (typeof mainContent === 'object') {
    return documentToReactComponents(mainContent)
  }

  return mainContent
}

const renderContent = ({ id, mainContent, moreInfo }: Content) => (
  <div key={id} className="content-wrapper">
    <div className="bubble">
      <span>{deriveMainContent(mainContent)}</span>
    </div>
    {moreInfo
      && (
        <div className="more-info">
          {documentToReactComponents(moreInfo)}
        </div>
      )}
  </div>
)

const cardWithHeadingBubble = ({ heading, content, background }: CardWithHeadingBubbleProps) => (
  <ContraceptionComparisonDetailTextWithBubbleCard
    header={heading}
    content={content.map(renderContent)}
    background={background}
  />
)

export default cardWithHeadingBubble
