const checkDobMatch = ({ dob, dobConfirm }) => {
  if (!dob || !dobConfirm) return { context: { dobConfirmed: false } }
  const d1 = new Date(dob.yyyy, dob.mm - 1, dob.dd)
  const d2 = new Date(dobConfirm.yyyy, dobConfirm.mm - 1, dobConfirm.dd)
  const dobConfirmed = d1.getTime() === d2.getTime()

  return {
    context: {
      dobConfirmed,
    },
  }
}

export default checkDobMatch
