import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { ContraceptionOption } from './types'
import Selection from './selection'
import Detail from './detail'

interface ContraceptionComparisonToolContainerProps {
  toolHeading: string,
  resetButtonText: string,
  options: ContraceptionOption[],
  compareOtherOptionsButtonText: string
}

const ContraceptionToolWrapper = styled.div`
    display: flex;
  justify-content: center;
`

const contraceptionComparisonToolContainer = ({
  toolHeading,
  resetButtonText,
  options,
  compareOtherOptionsButtonText,
} : ContraceptionComparisonToolContainerProps) => {
  const [selectedOptions,
    setSelectedOptions] = useState<ContraceptionOption[]>([])
  const [activeScreen, setActiveScreen] = useState<string>('selection')

  useEffect(() => {
    if (selectedOptions.length === 2) {
      return setActiveScreen('details')
    }

    return setActiveScreen('selection')
  }, [selectedOptions])

  const addToSelection = (contraceptionId: string) => {
    const selectedContraception = options.find((option) => option.id === contraceptionId)

    if (selectedContraception) {
      if (selectedOptions.includes(selectedContraception)) {
        return
      }

      setSelectedOptions([...selectedOptions, selectedContraception])
    }
  }

  const removeFromSelection = (contraceptionId: string) => {
    const newSelection = selectedOptions.filter((option) => option.id !== contraceptionId)

    setSelectedOptions(newSelection)
  }

  const resetSelection = () => {
    setSelectedOptions([])
  }

  const setActiveScreenBackToSelection = () => {
    resetSelection()
    setActiveScreen('selection')
    window.scrollTo(0, 0)
  }

  const selectionScreen = (
    <Selection
      resetButtonText={resetButtonText}
      options={options}
      selectedOptions={selectedOptions}
      addToSelectionHandler={addToSelection}
      removeFromSelectionHandler={removeFromSelection}
      resetSelectionHandler={resetSelection}
    />
  )

  const detailScreen = (
    <Detail
      selectedOptions={selectedOptions}
      compareOtherOptionsHandler={setActiveScreenBackToSelection}
      removeFromSelectionHandler={removeFromSelection}
      compareOtherOptionsButtonText={compareOtherOptionsButtonText}
    />
  )

  return (
    <ContraceptionToolWrapper>
      <div className="col-12 col-md-8">
        <h2>{toolHeading}</h2>
        {activeScreen === 'selection' ? selectionScreen : detailScreen}
      </div>
    </ContraceptionToolWrapper>

  )
}

export default contraceptionComparisonToolContainer
