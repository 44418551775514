import airbrake from '../../../services/exception-notifier/client'

const sendDataToAdmin = async (ctx) => {
  const res = await fetch('/api/orders', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ data: ctx }),
  })
  if (res.ok) return res

  const contentType = res?.headers?.get('content-type')
  const message = (contentType && contentType.indexOf('application/json') !== -1)
    ? (await res.json())?.errors[0]?.message
    : res?.statusText

  await airbrake.notify(`Client side sending data to admin failed! Status: ${res?.status} Error: ${message}`)
  throw new Error('We\'re sorry. Something has gone wrong.')
}

export default sendDataToAdmin
