import { ReactNode } from 'react'
import { Button } from '@sh24/ui-components'
import styled from 'styled-components'
import useTranslations from '../../../utils/use-translations'
import { formatDate, formatTime } from './utils'
import { State } from './types'

const BookedViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing.sm};
`

const BookedAt = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const CancellationText = styled.p`
  text-align: center;  
`

const BookedView = ({
  bookedAt,
  cancellationText,
  loading,
  onCancel,
}: {
  bookedAt: string,
  cancellationText: ReactNode | null,
  loading: State['loading'],
  onCancel: () => Promise<void>,
}) => {
  const translations = useTranslations()
  const weWillCallYou = translations['consultationAppointmentBookingModule.weWillCallYou']
  const cancelButtonText = translations['consultationAppointmentBookingModule.cancelAppointment']

  return (
    <BookedViewContainer>
      {weWillCallYou}
      <BookedAt className="body-lg mb-md">
        <div>
          <strong>{formatDate(new Date(bookedAt))}</strong>
        </div>
        <div>
          <strong>{formatTime(new Date(bookedAt))}</strong>
        </div>
      </BookedAt>

      <CancellationText className="mb-sm">{cancellationText}</CancellationText>
      <Button
        text={cancelButtonText}
        onClick={() => onCancel()}
        loading={loading}
        disabled={loading}
        variation="secondary"
      />
    </BookedViewContainer>
  )
}

export default BookedView
