import { createMachine } from 'xstate'

const machineConfig = {
  id: 'order-journey-machine',
  initial: 'idle',
  context: {
    currentStep: 0,
  },
  states: {
    idle: {
      on: {
        HYDRATE: {
          actions: ['hydrateContext'],
          target: 'step',
        },
      },
    },
    nextStep: {
      entry: ['clearErrors', 'clearRedundantAnswers', 'setCurrentToNext', 'updateSession', 'updateKickouts'],
      always: [
        { target: 'kickout', cond: 'shouldKickout' },
        { target: 'complete', cond: 'isComplete' },
        { target: 'service', cond: 'isValidService' },
        { target: 'step', cond: 'isValidStep' },
        { target: 'nextStep' },
      ],
    },
    previousStep: {
      entry: ['setCurrentToPrevious', 'updateSession'],
      always: [
        { target: 'step', cond: 'isValidStep' },
        { target: 'previousStep' },
      ],
    },
    step: {
      entry: ['calculateTotals'],
      initial: 'editing',
      onDone: [
        { target: '#order-journey-machine.nextStep' },
      ],
      states: {
        editing: {
          on: {
            UPDATE_URL: {
              actions: ['updateUrl'],
            },
            SAVE_PARAMS: {
              actions: ['saveQueryParams'],
            },
            UPDATE: {
              actions: ['clearErrors', 'updateContext', 'calculateTotals'],
            },
            NEXT: {
              target: 'validating',
            },
            BACK: {
              target: '#order-journey-machine.previousStep',
            },
          },
        },
        validating: {
          invoke: {
            src: 'validationService',
            onDone: {
              target: 'valid',
            },
            onError: {
              actions: ['setErrors'],
              target: 'invalid',
            },
          },
        },
        valid: {
          type: 'final',
        },
        invalid: {
          on: {
            UPDATE: {
              target: 'editing',
              actions: ['clearErrors', 'updateContext', 'calculateTotals'],
            },
            BACK: {
              target: '#order-journey-machine.previousStep',
            },
          },
        },
      },
    },
    service: {
      invoke: {
        src: 'serviceProxy',
        onDone: {
          actions: ['processServiceResponse'],
          target: 'nextStep',
        },
        onError: {
          actions: ['setErrors'],
          target: 'previousStep',
        },
      },
    },
    kickout: {
      entry: ['clearSession', 'kickoutRedirect'],
      type: 'final',
    },
    complete: {
      entry: ['setAsCurrentJourney', 'completeRedirect'],
      type: 'final',
    },
  },
}

export default createMachine(machineConfig)
