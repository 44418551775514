import styled from 'styled-components'

const Anchor = styled.span`
  display: block;
  height: 0;
  width: 0;
  transform: translateY(-110px); // comfortably allow for fixed header.
`

const AnchorModule = ({ slug }) => (
  <Anchor id={slug} />
)

export default AnchorModule
