// Verify a string based on a luhn mod n algorithm https://en.wikipedia.org/wiki/Luhn_mod_N_algorithm
const verifyLuhnModN = (characterSet, input) => {
  const characterSetLength = characterSet.length // this is our base N
  let factor = 1
  let sum = 0

  // Starting from the right, work leftwards
  // The initial "factor" will always be "1" since the last character is the check character
  //
  // eslint-disable-next-line no-plusplus
  for (let i = input.length - 1; i >= 0; i--) {
    const codePoint = characterSet.indexOf(input[i])
    let addend = factor * codePoint

    // Alternate the "factor" that each "codePoint" is multiplied by
    factor = (factor === 2) ? 1 : 2

    // Sum the digits of the "addend" as expressed in base "n"
    addend = Math.floor(addend / characterSetLength) + (addend % characterSetLength)
    sum += addend
  }

  const remainder = sum % characterSetLength

  return remainder === 0
}

export default verifyLuhnModN
