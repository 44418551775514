/**
 * Implements the same age calculation as the common gem.
 * https://github.com/sh24/common/blob/master/lib/common/age.rb
 */
const calculateAge = ({ dd, mm, yyyy }) => {
  const dob = new Date(Date.UTC(yyyy, mm - 1, dd))
  const now = new Date(Date.now())

  const years = now.getFullYear() - dob.getFullYear()
  const birthdayYet = now.getMonth() > dob.getMonth()
    || (now.getMonth() === dob.getMonth()
      && now.getDate() >= dob.getDate())

  return years - (birthdayYet ? 0 : 1)
}

export default calculateAge
