import { arrayIntersects } from '../../../utils/helpers'

const isRecommended = (response, yes, no) => {
  const responseArray = Array(response).flat()

  if (arrayIntersects(responseArray, yes)) return true
  if (arrayIntersects(responseArray, no)) return false
  return null
}

const getHepCRecommendation = (ctx) => {
  const results = [
    isRecommended(ctx.bloodTransfusionOrSurgery, ['before_1992', 'outside_ireland_or_the_uk'], ['none_of_the_above']),
    isRecommended(ctx.piercingsOrTattoos, ['before_1992', 'outside_ireland_or_the_uk', 'non_professional'], ['none_of_the_above']),
    isRecommended(ctx.unexplainedLiverProblems, ['yes'], ['no', 'yes_and_attending_liver_clinic']),
    isRecommended(ctx.sharedDrugsEquipment, ['yes', 'prefer_not_to_say'], ['no']),
    isRecommended(ctx.previousContactWithHepC, ['lived_with_someone_who_had_hepatitis_c', 'parent_had_hepatitis_c'], ['none_of_the_above']),
    isRecommended(ctx.previouslyTestedForHepC, ['yes', 'prefer_not_to_say'], ['dont_know', 'no']),
    isRecommended(ctx.beenInPrison, ['yes', 'prefer_not_to_say'], ['no']),
    ctx.countryOfBirth ? [
      'ALB', // Albania
      'AGO', // Angola
      'ARM', // Armenia
      'AZE', // Azerbaijan
      'BHR', // Bahrain
      'BEN', // Benin
      'BFA', // Burkina Faso
      'KHM', // Cambodia
      'CMR', // Cameroon
      'CPV', // Cape Verde
      'CAF', // Central African Republic
      'TCD', // Chad
      'COG', // Congo
      'COD', // Democratic Republic of the Congo
      'CIV', // Côte d’Ivoire
      'EGY', // Egypt
      'GNQ', // Equatorial Guinea
      'EST', // Estonia
      'GAB', // Gabon
      'GMB', // Gambia
      'GEO', // Georgia
      'GHA', // Ghana
      'GIN', // Guinea
      'GNB', // Guinea-Bissau
      'IRQ', // Iraq
      'ITA', // Italy
      'JOR', // Jordan
      'KAZ', // Kazakhstan
      'KWT', // Kuwait
      'KGZ', // Kyrgyzstan
      'LVA', // Latvia
      'LBN', // Lebanon
      'LBR', // Liberia
      'LTU', // Lithuania
      'MLI', // Mali
      'MDA', // Moldova
      'MNG', // Mongolia
      'NER', // Niger
      'NGA', // Nigeria
      'OMN', // Oman
      'PAK', // Pakistan
      'PSE', // Palestine
      'PRI', // Puerto Rico
      'ROU', // Romania
      'RUS', // Russia
      'STP', // Sao Tome and Principe
      'SEN', // Senegal
      'SLE', // Sierra Leone
      'SYR', // Syria
      'TWN', // Taiwan
      'TJK', // Tajikistan
      'THA', // Thailand
      'TGO', // Togo
      'TKM', // Turkmenistan
      'UKR', // Ukraine
      'ARE', // United Arab Emirates
      'UZB', // Uzbekistan
      'ESH', // Western Sahara
      'YEM', // Yemen
    ].includes(ctx.countryOfBirth) : null,
  ]
  if (results.some((e) => e === true)) return { context: { hepCRecommendation: true } }
  if (results.every((e) => e === false)) return { context: { hepCRecommendation: false } }
  return { context: { hepCRecommendation: null } }
}

export default getHepCRecommendation
