const withCustomMessages = (validations, errorMessages) => {
  if (!validations) return validations
  const entries = Object.entries(validations)
  const replaced = entries.map(([key, rules]) => {
    const mappedRules = rules.map((rule) => {
      if (typeof rule === 'object' && rule.messageKey) {
        return {
          rule: rule.rule,
          ...(errorMessages[rule.messageKey]
            ? { message: errorMessages[rule.messageKey] }
            : {}
          ),
        }
      }
      return rule
    })
    return [key, mappedRules]
  })

  return Object.fromEntries(replaced)
}

export default withCustomMessages
