import { getServiceName } from '../../config'
import { buildProduct } from '../../helpers'
import { getBoolean } from '../../../../services/admin/mappers/mapper-helpers'

/* eslint-disable camelcase */
const callApi = async (ctx) => {
  const {
    journey,
    region_lookup,
    genderIdentity,
    genderIdentitySameAsBirthSex,
    ethnicity,
    age,
    sexualPreference,
    username,
  } = ctx
  const { slug } = journey

  const data = JSON.stringify({
    regionLookup: region_lookup,
    service: getServiceName({ slug }),
    genderIdentity,
    genderIdentitySameAsBirthSex,
    ethnicity,
    age,
    sexualPreference,
    prepUser: getBoolean(ctx, 'prepUser'),
    injectedDrugs: getBoolean(ctx, 'injectedDrugs'),
    injectedDrugsGroupsChemsexFisting: getBoolean(ctx, 'injectedDrugsGroupsChemsFisting'),
    paidSexWork: getBoolean(ctx, 'paidSexWork'),
    hivPositive: !!(getBoolean(ctx, 'hivDiagnosed') && getBoolean(ctx, 'hivDiagnosedConfirmation')),
    hepatitisBVaccinated: getBoolean(ctx, 'hepBVaccinated'),
    hepatitisCTestInTheLast12Months: getBoolean(ctx, 'hepatitisCTestInTheLast12Months'),
    username,
  })

  return fetch('/api/product-offer', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: data,
  })
}

const stiAndPrepStrategy = async (ctx) => {
  const { products: journeyProducts } = ctx.journey
  const { currency, locale } = ctx

  const result = await callApi(ctx)

  const parsed = await result.json()

  if (result.status !== 200) return Promise.reject(parsed)

  const { orderType } = ctx
  if (['prep_initiation', 'prep_continuation_prep_and_sti', 'prep_continuation_prep_only'].includes(orderType)) {
    const prep = {
      generic_prep: {
        mandatory: true,
        preselected: true,
      },
    }
    parsed.data.product_offer.products = { ...parsed.data.product_offer.products, ...prep }
  }

  const { products: offeredProducts, extra_products } = parsed?.data?.product_offer || {}

  const products = journeyProducts
    .filter((product) => {
      if (product.isExtra) return false
      return !!offeredProducts?.[product.identifier]
    })
    .map((product) => {
      const { mandatory, preselected } = offeredProducts[product.identifier]
      return buildProduct({
        product,
        mandatory,
        preselected,
        currency,
        locale,
      })
    })

  return [products, extra_products]
}

const productStrategy = async (ctx) => {
  // fetch bolt-on data
  const result = await callApi(ctx)
  const parsed = await result.json()
  const { extra_products } = parsed?.data?.product_offer || {}

  // select first generic product from products array
  const { products: journeyProducts } = ctx.journey
  const { currency, locale } = ctx
  const products = journeyProducts
    .filter((product) => !product.isExtra)
    .slice(0, 1)
    .map((product) => buildProduct({
      product,
      mandatory: true,
      preselected: true,
      currency,
      locale,
    }))

  return [products, extra_products]
}

const extrasStrategy = (ctx, offeredExtras = []) => {
  const { products: journeyProducts } = ctx.journey
  const { currency, locale } = ctx

  return journeyProducts
    .filter((product) => product.isExtra && offeredExtras.includes(product.identifier))
    .map((product) => buildProduct({
      product,
      mandatory: false,
      preselected: false,
      currency,
      locale,
    }))
}

const sh24ProductAvailabilityHandler = async (ctx) => {
  const { orderType } = ctx
  const [products, extra_products] = ['sti_test_order', 'prep_initiation', 'prep_continuation_prep_and_sti', 'prep_continuation_sti_only'].includes(orderType)
    ? await stiAndPrepStrategy(ctx)
    : await productStrategy(ctx)

  const extras = extrasStrategy(ctx, extra_products)

  return {
    products: [
      ...products,
      ...extras,
    ],
  }
}

export default sh24ProductAvailabilityHandler
