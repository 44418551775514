import airbrake from '../../../services/exception-notifier/client'
import verifyLuhnModN from '../helpers/luhn-mod-n-verifier'

const OLD_TOKEN_FORMAT = /^[12]-.{3}-.{3}$/
const NEW_TOKEN_FORMAT = /^[12]-.{7}$/
const OLD_TOKEN_CHARACTER_SET = 'abcdefghjkmnpqrstuvwxyz'
const NEW_TOKEN_CHARACTER_SET = 'abcdefghjkmnpqrstuwxyz'

const genderCodes = {
  1: 'male',
  2: 'female',
}

const validTokenFormat = (chlamydiaTreatmentToken) => (
  OLD_TOKEN_FORMAT.test(chlamydiaTreatmentToken) || NEW_TOKEN_FORMAT.test(chlamydiaTreatmentToken)
)

const validCheckDigit = (chlamydiaTreatmentToken) => {
  const tokenWithoutGenderOrHypens = chlamydiaTreatmentToken.replace(/[\d-]/g, '')

  if (tokenWithoutGenderOrHypens.length === 6) {
    return verifyLuhnModN(OLD_TOKEN_CHARACTER_SET, tokenWithoutGenderOrHypens)
  }
  return verifyLuhnModN(NEW_TOKEN_CHARACTER_SET, tokenWithoutGenderOrHypens)
}

const genderFromToken = (chlamydiaTreatmentToken) => {
  const prefix = chlamydiaTreatmentToken[0]
  return genderCodes[prefix]
}

const validateChlamydiaTreatmentToken = async ({ token, brand }) => {
  if (!validTokenFormat(token)) {
    await airbrake.notify(`Chlamydia treatment token ${token} is in incorrect format`)

    return {
      context: {
        invalidTokenReason: 'token_invalid',
      },
    }
  }

  if (!validCheckDigit(token)) {
    await airbrake.notify(`Check digit is incorrect on chlamydia treatment token ${token}`)
    return {
      context: { invalidTokenReason: 'token_invalid' },
    }
  }

  const data = JSON.stringify({
    chlamydiaTreatmentToken: token,
    brand,
  })

  const result = await fetch('/api/validate-chlamydia-treatment-token', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: data,
  })

  const parsed = await result.json()

  if (result.status !== 200) return Promise.reject(parsed)

  const tokenData = parsed?.data?.chlamydia_treatment_token_validator

  if (tokenData.result !== 'token_valid') {
    return {
      context: { invalidTokenReason: tokenData.result },
    }
  }

  return {
    context: {
      gender: genderFromToken(token),
      offerToken: token,
    },
  }
}

export default validateChlamydiaTreatmentToken
